//
// Top Toolbar
// ---------------------------------------------

.toolbar-products {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: 0 27px;

    // Modes
    .modes {
        margin-bottom: 10px;
        width: 100%;
        flex: none;
    }

    // Amount
    .toolbar-amount {
        display: none;
        line-height: 1;
        margin: 0;

        @include bp(min-width, $bp-medium) {
            padding-top: 15px;
        }
    }

    // Limiter
    .limiter {

        &-text,
        .label,
        .control {
            display: inline-block;
        }

        .control {
            margin: 0 6px;

            select {
                margin: 0;
            }
        }
    }

    // Sorter
    .sorter {
        margin-left: 20px;

        &-label {}

        select {
            margin: 0 6px;
            width: auto;
        }

        &-action {
            $size: 6px;
            vertical-align: middle;
            display: inline-block;
            height: $size;
            width: $size;

            span {
                display: none;
            }

            &::before {
                @include triangle($size, $size, $size, $size);
                transform: translateY(-50%) rotate(180deg);
                color: $color-base;
                font-size: 20px;
            }

            &.sort-desc::before {
                transform: rotate(0deg);
            }
        }
    }

    // Hidden
    .pages {
        display: none;
    }
}


//
// Bottom Toolbar
// ---------------------------------------------

.products.wrapper ~ .toolbar-products {

    // Pages
    .pages {
        padding-top: 20px;
        display: block;
        width: 100%;

        .items {
            text-align: center;
            display: block;

            .item {
                display: inline-block;
            }
        }
    }

    // Hidden
    .toolbar-amount,
    .limiter,
    .sorter,
    .modes {
        display: none;
    }
}