// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
$mobile-cart-padding: 15px;
$cart-price-color: $color-gray40;
$cart-item-cell-padding-top: 20px;
//
//  Common
//  _____________________________________________
//
//  Shopping cart
//  ---------------------------------------------
.checkout-cart-index {
    .page-main {
        padding-left: 0;
        padding-right: 0;
    }

    .page-title-wrapper {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
    }
}
//  Cart container
.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }
}
//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    background: $sidebar__background-color;
    margin-bottom: $indent__m;
    padding: 20px 0 $indent__m;

    & > .title {
        font-size: 24px;
        display: none;
        font-weight: $font-weight__light;
        margin: 12px 0;
    }

    .block {
        // TO DO - The selector ".abs-incl-excl-tax" was not found.
        // @extend .abs-cart-block;
        form:not(:last-of-type) {
            .fieldset {
                margin: 0 0 $indent__m;
            }
        }

        .price {
            font-weight: $font-weight__bold;
        }

        .field {
            margin: 0 0 16px;

            &.note {
                display: none;
            }
        }

        .actions-toolbar {
            & > .primary {
                text-align: left;

                .action.primary {
                    // TO DO - The selector ".abs-revert-to-action-secondary" was not found.
                    // @extend .abs-revert-to-action-secondary !optional;
                    width: auto;
                }
            }
        }

        .fieldset.estimate {
            & > .legend + br,
            & > .legend {
                @extend .abs-no-display;
            }
        }
        // TO DO - The selector ".abs-cart-block" was not found.
        // @extend .abs-cart-block !optional;
        .title {
            strong {
                font-size: 14px;
                font-weight: $font-weight__semibold;
            }
        }

        .item-options {
            margin: 0 0 16px;

            .field {
                .radio {
                    float: left;
                }

                .radio {
                    & + .label {
                        display: block;
                        margin: 0;
                        overflow: hidden;
                    }
                }
            }
        }
        &.shipping {
            display: none;
        }
    }

    .page-main & {
        .block {
            margin: 0 20px;
        }
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin: $indent__base 0 0;
        padding: 0 $mobile-cart-padding;
        text-align: center;

        .action.primary.checkout {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            margin-bottom: $indent__m;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .message {
        padding-left: $indent__base;

        & > *:first-child:before {
            display: none;
        }
    }

}
//  Totals block
.opc-block-summary,
.cart-totals {
    @extend .abs-sidebar-totals;

    tbody,
    tfoot {
        .mark {
            text-align: left;
        }
    }

    #totals-body {
        display: flex;
        flex-direction: column;

        .mark {
            min-width: 175px;
        }

        .amount {
            min-width: 75px;
        }

        .totals.sub.excl {
            order: 1;
        }

        .totals-tax-details {
            order: 3;
        }

        tr.totals {
            order: 2;
        }

        .totals.shipping.incl {
            order: 4;
        }

        .grand.totals.excl,
        .totals.shipping.excl {
            //display: none;
        }

        .totals.incl {
            order: 5;
        }
    }
}
#cart-totals {
    .totals-tax-summary {
        display: none;
    }
    .totals-tax-details {
        border: 0;
    }
}
//  Products table
.cart {
    &.table-wrapper {
        .cart {
            thead {
                tr th.col {
                    border-bottom: $border-width__base solid $border-color__base;
                    padding-bottom: 15px;
                    padding-top: 24px;
                }
            }

            tbody {
                td {
                    border: 0;
                }
            }

            & > .item {
                border-bottom: $border-width__base solid $border-color__base;
                position: relative;
            }
        }

        .col {
            padding-top: 15px;

            &.msrp,
            &.price,
            &.subtotal {
                // TO DO - The selector ".abs-incl-excl-tax" was not found.
                // @extend .abs-incl-excl-tax !optional;
                padding: $cart-item-cell-padding-top 11px $indent__s;
                text-align: center;
            }

            &.qty {
                padding: $indent__base 11px $indent__s;
                text-align: center;

                .label {
                    @extend .abs-visually-hidden;
                }

                .input-text {
                    height: 36px;
                    margin-top: -7px;
                    text-align: center;
                    width: 55px;
                }
            }

            & > .price {
                color: $primary__color__lighter;
                font-size: 18px;
                font-weight: $font-weight__bold;
            }

            &.tax {
                padding-top: 20px;
            }
        }

        .item-actions {
            td {
                padding-bottom: 0;
                padding-left: $mobile-cart-padding;
                padding-right: $mobile-cart-padding;
                white-space: normal;
            }
        }

        .item {
            .col.item {
                display: block;
                min-height: 75px;
                padding: 15px $mobile-cart-padding $indent__s 90px;
                position: relative;
            }
        }

        .actions-toolbar {
            @extend .abs-add-clearfix;
            min-height: 20px;
            padding-bottom: 15px;
            position: relative;
            text-align: right;

            & > .action-delete {
                position: static;
                right: 0;
                top: 0;
                @extend .abs-action-button-as-link;
                @include lib-icon-font(
                    $_icon-font-content: $icon-trash,
                    $_icon-font-size        : 34px,
                    $_icon-font-line-height : 20px,
                    $_icon-font-text-hide   : true,
                    $_icon-font-color       : $minicart-icons-color,
                    $_icon-font-color-hover : $primary__color,
                    $_icon-font-color-active: $minicart-icons-color
                );
            }
        }

        .action {
            margin-right: $indent__m;

            &:last-child {
                margin-right: 0;
            }

            &.help.map {
                @extend .abs-action-button-as-link;
                font-weight: $font-weight__regular;
            }
        }

        .product {
            &-item-photo {
                display: block;
                left: $mobile-cart-padding;
                max-width: 65px;
                padding: 0;
                position: absolute;
                top: 15px;
                width: 100%;
            }

            &-item-name {
                font-size: 18px;
                display: block;
                margin: -3px 0 $indent__xs;
            }
        }

        .gift-registry-name-label {
            &:after {
                content: ':';
            }
        }

        //  Product options
        .item-options {
            @extend .abs-product-options-list;
            @extend .abs-add-clearfix;
            margin-bottom: 0;
        }

        .product-item-name + .item-options {
            margin-top: $indent__base;
        }

        .cart-tax-total {
            @extend .abs-tax-total;

            &-expanded {
                @extend .abs-tax-total-expanded;
            }
        }

        .product-image-wrapper {
            @extend .abs-reset-image-wrapper;
        }

        .action.configure {
            display: inline-block;
            margin: $indent__s 0 0;
        }

        .item .message {
            margin-top: $indent__base;
        }
    }
    .table-caption {
        display: none;
    }
}

//  Discount
.cart-discount {
    @extend .abs-discount-block;
    border-bottom: $border-width__base solid $border-color__base;
    clear: left;
}
//  Empty cart
.cart-empty {
    padding-left: $layout__width-xs-indent;
    padding-right: $layout__width-xs-indent;
}

.cart .cart-tax-info,
.cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.table-order-items.table-wrapper,
.order-items.table-wrapper {
    .col.tax,
    .col.msrp,
    .col.price,
    .col.qty,
    .col.subtotal {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__s) {
    .cart {
        .table.items {
            .col.item,
            .item-actions td {
                // TO DO - The selector ".abs-col-no-prefix" was not found.
                // @extend .abs-col-no-prefix !optional;
            }
            .col.qty {
                text-align: center;
            }

            tbody > tr > td:last-child {
                // TO DO - The selector ".abs-no-border-bottom-top" was not found.
                // @extend .abs-no-border-bottom-top !optional;
            }
        }
    }

    .cart-totals {
        .totals {
            // TO DO - The selector ".abs-sidebar-totals-mobile" was not found.
            // @extend .abs-sidebar-totals-mobile !optional;
        }
    }
}

@include max-screen($screen__m) {
    //  Cart container
    .cart-container {
        .form-cart {
            .actions.main {
                text-align: center;
                margin: 20px 10px;
                .action {
                    margin: 5px;
                }
            }
        }
    }

    .cart-discount {
        border-bottom: $border-width__base solid $border-color__base;
    }

    .cart {
        &.table-wrapper {
            border-top: $border-width__base solid $border-color__base;

            thead {
                .col {
                    &.item,
                    &.msrp,
                    &.price,
                    &.qty,
                    &.subtotal,
                    &.tax {
                        display: none;
                    }
                }
            }

            .col {
                &.msrp,
                &.price,
                &.qty,
                &.subtotal,
                &.tax {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    white-space: nowrap;
                    width: 33%;
                    text-align: center;

                    &:before {
                        content: attr(data-th);
                        display: block;
                        font-weight: $font-weight__semibold;
                        padding-bottom: 10px;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item .col.item {
                padding-bottom: 0;
                float: left;
                width: 100%;
            }

            tbody > tr > td:last-child {
                border: 0;
            }
        }
    }

    .cart-totals {
        padding-left: $mobile-cart-padding;
        padding-right: $mobile-cart-padding;

        .table-wrapper {
            border-top: 0;
        }

        .totals {
            tbody > tr:not(:last-child) > td:last-child {
                border: 0;
            }
        }
    }
}

//
//  Common
//  _____________________________________________
//  Cross sell
.block {
    &.crosssell {
        padding: 0 $mobile-cart-padding;
        margin-top: 70px;

        .product-item-info {
            width: 200px;
        }
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .checkout-cart-index {
        .page-main {
            padding-left: $layout__width-xs-indent;
            padding-right: $layout__width-xs-indent;
        }

        .page-title-wrapper {
            // TO DO - The selector ".abs-revert-side-paddings" was not found.
            // @extend .abs-revert-side-paddings !optional;
        }
    }
    //  Cart container
    .cart-container {
        @extend .abs-add-clearfix-desktop;

        .form-cart {
            @extend .abs-shopping-cart-items-desktop;

            .actions.main {
                text-align: right;
                margin: 20px 10px;
            }
        }

        .widget {
            float: left;
        }
    }
    //  Summary block
    .cart-summary {
        @include lib-layout-column(2, 2, $layout-column-checkout__width-left);
        padding: 1px $indent__base $indent__m;
        position: relative;

        & > .title {
            display: block;
        }

        .fieldset {
            .actions-toolbar {
                margin-left: 0;

                & > .secondary {
                    float: none;
                }
            }
        }

        .block {
            & > .title {
                padding-left: 0;

                &:after {
                    right: 3px;
                }
            }

            .content {
                // TO DO - The selector ".abs-revert-side-paddings" was not found.
                // @extend .abs-revert-side-paddings !optional;
            }

            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }

        .checkout-methods-items {
            padding: 0;
        }
        .page-main & {
            .block {
                margin: 0;
            }
        }
    }
    //  Products table
    .cart {
        &.table-wrapper {
            .items {
                // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            tbody td {
                padding-top: $cart-item-cell-padding-top;
            }

            .item {
                .col.item {
                    padding: $cart-item-cell-padding-top 8px $indent__base 0;
                }
            }

            .item-actions td {
                padding: 0;
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    padding-bottom: 35px;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }
        }
    }

    //  Discount
    .cart-discount {
        @extend .abs-discount-block-desktop;
        @include lib-layout-column(2, 1, $layout-column-checkout__width-main);
        border: 0;
        box-sizing: border-box;
        padding-right: 4%;
    }

    //  Empty cart
    .cart-empty {
        // TO DO - The selector ".abs-revert-side-paddings" was not found.
        // @extend .abs-revert-side-paddings !optional;
    }

    //  Cross sell
    .block {
        &.crosssell {
            @extend .abs-add-box-sizing-desktop;
            @include lib-layout-column(2, 1, $layout-column-checkout__width-main);
            padding: 0 4% 0 0;

            .products-grid .product-item {
                width: 25%;
            }
        }
    }
    .gift-options-cart-item {
        & + .action-towishlist {
            position: relative;
        }
    }
}
#shopping-cart-table {
    .item-actions td:before {
        display: none;
    }
}
