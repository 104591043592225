// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________

.minilist {
    .weee {
        display: table-row;
        font-size: 11px;

        &:before {
            display: none;
        }
    }
}

.price-container.weee {
    &:before {
        display: none;
    }
}
