// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________

//  PayPal Review Order page
.paypal-review {
    .block {
        @extend .abs-account-blocks;
        .actions-toolbar {
            .action {
                &.primary {
                    @extend .abs-revert-secondary-color;
                }
            }
        }
    }

    .actions-toolbar {
        margin-top: $indent__s;
    }

    .paypal-review-title {
        padding: 0 0 $indent__s;

        > strong {
            font-size: 24px;
            font-weight: $font-weight__light;
        }

        .action {
            display: inline-block;
            margin: 12px 0 0 $indent__l;
        }
    }

    .table-paypal-review-items {
        .col {
            &.price,
            &.subtotal {
                // TO DO - The selector ".abs-incl-excl-tax" was not found.
                // @extend .abs-incl-excl-tax !optional;
            }

            &.name {
                padding-top: 16px;
            }

            &.qty {
                text-align: center;
            }
        }

        .cart-tax-total {
            @extend .abs-tax-total;
            &-expanded {
                @extend .abs-tax-total-expanded;
            }
        }
    }

    .item-options {
        dt {
            display: inline-block;
            &:after {
                content: ': ';
            }
        }

        dd {
            margin: 0;
        }
    }
}

//  Discounts
.paypal-review-discount {
    @extend .abs-discount-block;
    border-bottom: $border-width__base solid $border-color__base;
    margin: 0 0 $indent__xl;
    padding: $indent__s 0 0;
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .table-paypal-review-items {
        .col {
            &.subtotal {
                text-align: left;
            }
        }

        .product-item-name {
            display: inline-block;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .paypal-review {
        .paypal-review-title {
            border-bottom: $border-width__base solid $border-color__base;
        }

        .block-content {
            @extend .abs-add-clearfix-desktop;

            .box-order-shipping-address,
            .box-order-shipping-method,
            .box-order-shipping-method + .box-order-billing-address {
                box-sizing: border-box;
                float: left;
                width: 33%;
            }

            .box-order-shipping-address {
                padding: 0 5%;
                width: 34%;
            }
        }

        .column.main & {
            .actions-toolbar {
                @extend .abs-reset-left-margin-desktop;
            }
        }

        .table-paypal-review-items {
            .col {
                &.price,
                &.qty {
                    text-align: center;
                }

                &.item {
                    width: 60%;
                }
            }
        }

        .col.subtotal,
        .mark,
        .amount {
            text-align: right;
        }
    }

    //  Discounts
    .paypal-review-discount {
        @extend .abs-add-clearfix-desktop;
        @extend .abs-discount-block-desktop;
        border-top: $border-width__base solid $border-color__base;
    }
}
