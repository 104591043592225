// /**
// * Copyright © 2016 Magento. All rights reserved.
// * See COPYING.txt for license details.
// */
$fotorama-duration-time: 0.3s;
$fotorama-arw-size: 95px;
$fotorama_close_size: 30px;
$size-fotorama-block: 50px;
$fotorama-thumb-arrow: 30px;

@mixin fotorama-abs-center() {
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    position: absolute;
    top: 50%;
}

@mixin fotorama-shadow-gradient($x, $y) {
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at $x $y, rgba(0, 0, 0, 0.4), transparent);
}

@mixin fotorama-arrow-gradient($d) {
    background-image: linear-gradient($d, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
}

@mixin fotorama-stretch() {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.fotorama-print-background {
    @media print {
        background: none;
    }
}

.fotorama-sprite {
    @extend .fotorama-print-background;
    background: url('../images/gallery.png') no-repeat;
}

.fotorama-inline-block {
    display: inline;
    zoom: 1;
    display: inline-block;
    vertical-align: middle;
}

.fotorama__zoom-in,
.fotorama__zoom-out {
    display: none;
}

.fotorama__fullscreen {
    .fotorama__zoom-in,
    .fotorama__zoom-out {
        position: absolute;
        width: 50px;
        height: 50px;
        display: block;
        margin-left: 20px;
        z-index: 1000;
    }

    .fotorama__zoom-out {
        @extend .fotorama-sprite;
        top: 51px;
        background-position: 0 (-$size-fotorama-block);
    }

    .fotorama__zoom-in {
        @extend .fotorama-sprite;
        top: 0;
        background-position: 0 0;
    }
}

.fotorama__zoom-in,
.fotorama__zoom-out {
    display: none;
}

.fotorama__fullscreen {
    .fotorama__zoom-in,
    .fotorama__zoom-out {
        position: absolute;
        width: 50px;
        height: 50px;
        display: block;
        margin-left: 20px;
        cursor: pointer;
    }

    .fotorama__zoom-out {
        @extend .fotorama-sprite;
        top: 51px;
        background-position: 0 (-$size-fotorama-block);
    }

    .fotorama__zoom-in {
        @extend .fotorama-sprite;
        top: 0;
        background-position: 0 0;
    }
}

.fotorama__zoom-in,
.fotorama__zoom-out {
    display: none;
}

.fotorama__fullscreen {
    .fotorama__zoom-in,
    .fotorama__zoom-out {
        position: absolute;
        width: 50px;
        height: 50px;
        display: block;
        margin-left: 20px;
        cursor: pointer;
    }

    .fotorama__zoom-out {
        @extend .fotorama-sprite;
        top: 51px;
        background-position: 0 (-$size-fotorama-block);
    }

    .fotorama__zoom-in {
        @extend .fotorama-sprite;
        top: 0;
        background-position: 0 0;
    }
}

.fotorama-grab-cursor {
    cursor: move;
    cursor: grab;
}

.fotorama-grabbing-cursor {
    cursor: move;
    cursor: grabbing;
}

.fotorama-gpu {
    transform: translateZ(0);
}

.fotorama-focus {
    outline: 0;
}

.fotorama-focus-overlay {
    &:after {
        @include fotorama-stretch();
        background-color: $color-blue2;
        border-radius: inherit;
        content: '';
    }
}

.fotorama__arr:focus:after,
.fotorama__fullscreen-icon:focus:after,
.fotorama__nav__frame:focus .fotorama__dot:after,
.fotorama__nav__frame:focus .fotorama__thumb:after,
.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
    background-color: rgba(0, 175, 234, 0.5);
    border-radius: inherit;
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
}

.fotorama-transform-disabled {
    transform: none;
}

.fotorama-transition-for-slide {
    transition-duration: 0;
    transition-property: transform, width;
    transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
}

.fotorama-no-select {
    user-select: none;
}

.fotorama-select {
    user-select: text;
}

.fotorama-empty-bg {
    background: url('data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=');
}

.fotorama-auto-margin {
    margin: auto;
    padding: 0;
}

.fotorama-hidden {
    left: -99999px;
    position: absolute;
    top: -99999px;
    z-index: -$z-index-10;
}

.fotorama-visible {
    left: auto;
    opacity: 1;
    position: relative;
    top: auto;
    z-index: auto;
}

.fotorama-no-tap {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.transitionDuration {
    transition-duration: 333ms;
}

.transitionDurationZero {
    transition-duration: 0;
}

.fotorama {
    min-width: 325px;
    overflow: hidden;
    &--fullscreen {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        background: $color-white;
        z-index: $z-index-10;
        .fotorama__wrap {
            max-width: 100% !important;
        }
    }
}


.fotorama__wrap {
    @extend .fotorama-no-select;
    text-size-adjust: 100%;
    direction: ltr;
    position: relative;
    z-index: 0;
}

.fotorama__wrap--rtl {
    .fotorama__stage__frame {
        direction: rtl;
    }
}

.fotorama__stage,
.fotorama__nav {
    max-width: 100%;
    overflow: hidden;
    position: relative;
}

.fotorama__wrap--pan-y {
    touch-action: pan-y;
}

.fotorama__stage__shaft {
    @extend .fotorama-no-tap;
    @include fotorama-stretch();
    position: relative;
}

.fotorama__wrap {
    .fotorama__pointer {
        cursor: pointer;
    }

    .fotorama__grab {
        @extend .fotorama-grab-cursor;
    }
}

.fotorama__wrap--slide {
    .fotorama__stage__frame {
        opacity: 1;
    }
}

.fotorama__grabbing * {
    @extend .fotorama-grabbing-cursor;
}

.fotorama__stage__frame {
    @extend .fotorama-no-tap;
    @include fotorama-stretch();
    overflow: hidden;

    &.fotorama__active {
        z-index: 8;
    }
}

.fotorama__wrap--fade {
    .fotorama__stage__frame {
        display: none;
    }

    .fotorama__stage__frame.fotorama__active,
    .fotorama__fade-front,
    .fotorama__fade-rear {
        display: block;
        left: 0;
        top: 0;
    }

    .fotorama__fade-front {
        z-index: 8;
    }

    .fotorama__fade-rear {
        z-index: 7;

        &.fotorama__active {
            z-index: 9;
        }
    }

    .fotorama__stage .fotorama__shadow {
        display: none;
    }
}

.fotorama__img {
    border: none;
    max-width: inherit;
    opacity: 0;

    .fotorama__loaded &,
    .fotorama__error & {
        opacity: 1;
    }
}

.fotorama__img--full {
    display: none;
}

.fotorama--fullscreen .fotorama__loaded--full {
    .fotorama__img {
        display: none;
    }

    .fotorama__img--full {
        display: block;
    }
}

.fotorama__html {
    @include fotorama-stretch();
}

.fotorama__nav {
    @extend .fotorama-auto-margin;
    @extend .fotorama-no-tap;
    display: none;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    z-index: 5;
}

.fotorama__nav__shaft {
    @extend .fotorama-inline-block;
    @extend .fotorama-no-tap;
    left: 0;
    position: relative;
    text-align: left;
    top: 0;
}

.fotorama__nav__frame {
    @extend .fotorama-auto-margin;
    @extend .fotorama-focus;
    @extend .fotorama-inline-block;
    @extend .fotorama-no-tap;
    position: relative;
    box-sizing: border-box;
}

.fotorama__nav--dots {
    display: block;
    text-align: center;

    .fotorama__nav__frame {
        height: 30px;
        width: 18px;
    }

    .fotorama__nav__frame--thumb,
    .fotorama__thumb-border {
        display: none;
    }
}

.fotorama__nav--thumbs {
    display: block;

    .fotorama__nav__frame {
        padding-left: 0;

        &:last-child {
            padding-right: 0;
        }
    }

    .fotorama__nav__frame--dot {
        display: none;
    }
}

.fotorama__active {
    .fotorama__dot {
        background-color: $color-orange-red1;
        border-color: $color-orange-red1;
    }
}

.fotorama__nav__frame {
    &.fotorama__active & {
        border-width: 3px;
        height: 0;
        width: 0;

        &:after {
            left: -3px;
            padding: 3px;
            top: -3px;
        }
    }
}

.fotorama__nav__frame:focus {
    @extend .fotorama-focus-overlay;
    box-shadow: none;

    &:after {
        top: -1px;
        left: -1px;
        padding: 1px;
    }
}

.fotorama__dot {
    border-radius: 6px;
    border: 1px solid $color-gray52;
    display: block;
    height: 4px;
    left: 6px;
    position: relative;
    top: 12px;
    width: 4px;
}

.fotorama__thumb {
    background-color: $color-gray92;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    .fotorama__nav__frame:focus & {
        @extend .fotorama-focus-overlay;
        z-index: 2;
    }
}

.fotorama__thumb-border {
    @extend .fotorama-print-background;
    box-sizing: border-box;
    backface-visibility: hidden;
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
    border: 1px solid $color-orange-red1;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 9;
}

.fotorama__caption {
    @extend .fotorama-gpu;
    bottom: 0;
    color: $color-black;
    font-size: 14px;
    left: 0;
    line-height: 1.5;
    position: absolute;
    right: 0;
    z-index: 12;

    a {
        border-bottom: 1px solid;
        border-color: fade($color-black, 0.5);
        color: $color-black;
        text-decoration: none;

        &:hover {
            color: $color-black;
            border-color: fade($color-black, 0.5);
        }
    }

    .fotorama__wrap--rtl & {
        left: auto;
        right: 0;
    }

    .fotorama__wrap--video &,
    .fotorama__wrap--no-captions & {
        display: none;
    }
}

.fotorama__caption__wrap {
    @extend .fotorama-inline-block;
    box-sizing: border-box;
    background-color: $color-white;
    padding: 5px 10px;
}

.fotorama--hidden {
    @extend .fotorama-hidden;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fotorama__spinner {
    @include fotorama-abs-center();
}

.fotorama__wrap--css3 {
    .fotorama__stage__shaft,
    .fotorama__nav__shaft,
    .fotorama__thumb-border {
        @extend .fotorama-transition-for-slide;
    }

    .fotorama__spinner {
        @extend .fotorama-gpu;
        animation: spinner 24s infinite linear;
    }

    .fotorama__stage,
    .fotorama__nav,
    .fotorama__stage__frame {
        @extend .fotorama-gpu;
    }

    .fotorama__html {
        @extend .fotorama-gpu;
        transition-duration: $fotorama-duration-time;
        transition-property: opacity;
        transition-timing-function: linear;
    }
}

.fotorama__wrap--video {
    .fotorama__stage__shaft,
    .fotorama__stage,
    .fotorama__stage__frame--video {
        @extend .fotorama-transform-disabled;
    }

    .fotorama__stage__frame--video {
        .fotorama__img,
        .fotorama__html {
            @extend .fotorama-transform-disabled;
            opacity: 0;
        }
    }
}

.fotorama__select {
    @extend .fotorama-select;
    cursor: auto;
}

.fotorama__no-select {
    @extend .fotorama-no-select;
}

.fotorama__load {
    @extend .fotorama-hidden;
}

.fotorama__video {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 32px;
    z-index: 10;

    iframe {
        @include fotorama-stretch();
    }
}

.fotorama__active {
    box-shadow: 0 0 0 transparent;
}

.fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close {
    @extend .fotorama-no-select;
    @extend .fotorama-no-tap;
    cursor: pointer;
    position: absolute;
}

.fotorama__arr {
    z-index: $z-index-9;
}

.fotorama__fullscreen-icon,
.fotorama__video-close {
    z-index: $z-index-10;
}

.fotorama__arr {
    box-sizing: border-box;
    bottom: 0;
    margin-top: -($fotorama-arw-size / 2);
    position: absolute;
    top: 47px;
    width: $fotorama-arw-size;

    .fotorama__arr__arr {
        @extend .fotorama-sprite;
        @include fotorama-abs-center();
        height: $size-fotorama-block;
        width: $size-fotorama-block;

        .ie9 & {
            margin: (-$size-fotorama-block / 2) 0 0 (-$size-fotorama-block / 2);
        }
    }
}

.fotorama__arr--prev {
    left: 0;

    .fotorama__arr__arr {
        background-position: (-$size-fotorama-block) (-$size-fotorama-block);
    }
}

.fotorama__arr--next {
    right: 0;

    .fotorama__arr__arr {
        background-position: (-$size-fotorama-block * 2) (-$size-fotorama-block);
    }
}

.fotorama__arr--disabled {
    display: none;
    cursor: default;
    opacity: 0.1;
    pointer-events: none;
}

.fotorama__fullscreen-icon {
    @extend .fotorama-sprite;
    background-position: 0 0;
    height: $size-fotorama-block;
    right: 2px;
    top: 2px;
    width: $size-fotorama-block;
    z-index: $z-index-10;
    display: none;
}

.fotorama__fullscreen-icon {
    //display: none;
    &:focus {
        @extend .fotorama-focus;
        border-radius: 50%;
    }
}

.fotorama--fullscreen {
    .fotorama__fullscreen-icon {
        //display: inline-block;
        background-position: (-$size-fotorama-block) 0;
    }
}

.fotorama__video-play {
    @extend .fotorama-sprite;
    @include fotorama-abs-center();
    background-position: (-$size-fotorama-block) (-$size-fotorama-block*2);
    height: $size-fotorama-block * 2;
    width: $size-fotorama-block * 2;

    .fotorama__wrap--css2 &,
    .fotorama__wrap--video .fotorama__stage & {
        display: none;
    }

    .fotorama__loaded &,
    .fotorama__error &,
    .fotorama__nav__frame & {
        display: block;
        opacity: 1;
    }

    .fotorama__nav__frame & {
        background-position: 0 (-$size-fotorama-block * 3);
        height: $size-fotorama-block;
        width: $size-fotorama-block;
    }
}

.fotorama__video-close {
    @extend .fotorama-sprite;
    background-position: -60px -9px;
    height: $fotorama_close_size;
    opacity: 0;
    right: 0;
    top: 0;
    width: $fotorama_close_size;
    z-index: 19;

    .fotorama__wrap--css2 & {
        display: none;
    }

    .fotorama__wrap--css3 & {
        transform: translate3d($fotorama-arw-size, -$fotorama-arw-size, 0);
    }

    .fotorama__wrap--video & {
        display: block;
        opacity: 1;
    }

    .fotorama__wrap--css3 {
        &.fotorama__wrap--video & {
            transform: translate3d(0, 0, 0);
        }
    }
}

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {
    .fotorama__arr,
    .fotorama__fullscreen-icon {
        opacity: 0;

        &:focus {
            opacity: 1;
        }
    }
}

.fotorama__wrap--toggle-arrows {
    &:not(.fotorama__wrap--video) {
        .fotorama__video-close {
            display: none;
        }
    }
}

.fotorama__wrap--toggle-arrows {
    &.fotorama__wrap--video {
        .fotorama__video-close {
            top: 97px;
            right: 93px;
            opacity: 1;
        }
    }
}

.fotorama__wrap--toggle-arrows {
    &:not(.fotorama__wrap--video) {
        .fotorama__video-close {
            display: none;
        }
    }
}

.fotorama__wrap--toggle-arrows {
    &.fotorama__wrap--video {
        .fotorama__video-close {
            top: 97px;
            right: 93px;
            opacity: 1;
        }
    }
}

.fotorama__wrap--toggle-arrows {
    &:not(.fotorama__wrap--video) {
        .fotorama__video-close {
            display: none;
        }
    }
}

.fotorama__wrap--toggle-arrows {
    &.fotorama__wrap--video {
        .fotorama__video-close {
            top: 97px;
            right: 93px;
            opacity: 1;
        }
    }
}

.fotorama__wrap--video {
    .fotorama__arr,
    .fotorama__fullscreen-icon {
        opacity: 0;
    }
}

.fotorama__wrap--css2 {
    &.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {
        .fotorama__arr,
        .fotorama__fullscreen-icon {
            display: none;

            &:focus {
                display: block;
            }
        }
    }

    &.fotorama__wrap--video {
        .fotorama__arr,
        .fotorama__fullscreen-icon {
            display: none;
        }
    }
}

.fotorama__wrap--css3 {
    &.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows {
        .fotorama__fullscreen-icon:not(:focus) {
            transform: translate3d($fotorama-arw-size, -$fotorama-arw-size, 0);
        }

        .fotorama__arr--prev:not(:focus) {
            transform: translate3d(-$fotorama-arw-size * 1.5, 0, 0);
        }

        .fotorama__arr--next:not(:focus) {
            transform: translate3d($fotorama-arw-size * 1.5, 0, 0);
        }
    }

    &.fotorama__wrap--video {
        .fotorama__fullscreen-icon {
            transform: translate3d($fotorama-arw-size, -$fotorama-arw-size, 0);
        }

        .fotorama__arr--prev {
            transform: translate3d(-$fotorama-arw-size * 1.5, 0, 0);
        }

        .fotorama__arr--next {
            transform: translate3d($fotorama-arw-size * 1.5, 0, 0);
        }
    }
}

.fotorama__wrap--css3 {
    .fotorama__arr:not(:focus),
    .fotorama__fullscreen-icon:not(:focus),
    .fotorama__video-play:not(:focus),
    .fotorama__video-close:not(:focus) {
        transition-duration: $fotorama-duration-time;
        transition-property: transform, opacity;
    }
}

.fotorama__stage,
.fotorama__nav {
    &:before,
    &:after {
        @extend .fotorama-gpu;
        background-repeat: no-repeat;
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        text-decoration: none;
        z-index: 10;
    }

    &:before {
        left: -10px;
        top: -10px;
    }

    &:after {
        right: -10px;
        bottom: -10px;
    }

    &.fotorama__shadows--left:before,
    &.fotorama__shadows--right:after {
        top: 0;
        bottom: 0;
        background-size: 1px 100%, 5px 100%;
        height: auto;
        width: 10px;
    }

    &.fotorama__shadows--top:before,
    &.fotorama__shadows--bottom:after {
        left: 0;
        right: 0;
        background-size: 100% 1px, 100% 5px;
        height: 10px;
        width: auto;
    }

    &.fotorama__shadows--left:before {
        @include fotorama-shadow-gradient(0, 50%);
        background-position: 0 0, 0 0;
        left: 0;
    }

    &.fotorama__shadows--right:after {
        @include fotorama-shadow-gradient(100%, 50%);
        background-position: 100% 0, 100% 0;
        right: 0;
    }

    &.fotorama__shadows--top:before {
        @include fotorama-shadow-gradient(50%, 0);
        background-position: 0 0, 0 0;
        top: 0;
    }

    &.fotorama__shadows--bottom:after {
        @include fotorama-shadow-gradient(50%, 100%);
        background-position: 0 100%, 0 100%;
        bottom: 0;
    }
}

.fotorama--fullscreen .fotorama__stage,
.fotorama--fullscreen .fotorama__nav,
.fotorama__wrap--no-shadows .fotorama__stage,
.fotorama__wrap--no-shadows .fotorama__nav,
.fotorama__wrap--fade .fotorama__stage {
    &:before,
    &:after {
        display: none;
    }
}

.fotorama__nav-wrap {
    .fotorama_vertical_ratio {
        .fotorama__img {
            transform: translateY(-50%);
            height: auto;
            position: absolute;
            top: 50%;
            width: 100%;
        }
    }

    .fotorama_horizontal_ratio {
        .fotorama__img {
            transform: translateX(-50%);
            height: 100%;
            left: 50%;
            position: absolute;
            width: auto;
        }
    }
}

.magnifier-thumb-wrapper {
    display: block;
    left: 0;
    position: relative;
    top: 0;
}

.magnifier-lens {
    border: solid 1px $color-gray-light2;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: $z-index-8;
}

.magnify-lens {
    background: rgba(255, 255, 255, 0.5);
    border: solid 1px $color-gray-light2;
    color: $color-white;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: $z-index-10;
}

.magnifier-loader-text {
    margin-top: 10px;
}

.magnifier-large {
    position: absolute;
    width: 32%;
    z-index: $z-index-1;
}

.magnifier-preview {
    bottom: 0;
    left: 58%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 215px;

    &:not(.hidden) {
        background-color: $color-white;
    }

    img {
        left: 0;
        max-width: inherit;
        position: absolute;
        top: 0;
    }
}

.fotorama__stage__frame {
    text-align: center;

    .fotorama__img {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        vertical-align: middle;
    }
}

.magnify-opaque {
    opacity: 0.5;
}

.magnify-hidden {
    display: none;
}

.fotorama__nav-wrap--vertical {
    &.fotorama__nav-wrap {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }

    .fotorama__nav__shaft {
        width: 100%;
        background-color: white;

        .fotorama__nav__frame--thumb {
            display: block;
            padding-bottom: inherit;
        }
    }
}

.fotorama--fullscreen {
    .fotorama__stage__frame {
        .fotorama__img {
            bottom: 0;
            left: 0;
            margin: auto;
            max-height: inherit;
            max-width: inherit;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.fotorama__nav-wrap--horizontal {
    .fotorama__thumb__arr--left,
    .fotorama__thumb__arr--right {
        bottom: 0;
        position: absolute;
        top: 0;
        width: $fotorama-thumb-arrow;
        z-index: $z-index-10;
        cursor: pointer;

        .fotorama__thumb__arr {
            @include fotorama-abs-center();
            width: 100%;

            .ie9 & {
                margin: (-$fotorama-thumb-arrow / 2) 0 0 (-$fotorama-thumb-arrow / 2);
            }
        }
    }

    .fotorama__thumb__arr--left {
        @include fotorama-arrow-gradient(left);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
        left: 0;

        .fotorama__thumb__arr {
            background-position: (-$fotorama-thumb-arrow) (-$fotorama-thumb-arrow);
        }
    }

    .fotorama__thumb__arr--right {
        @include fotorama-arrow-gradient(right);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
        right: 0;

        .fotorama__thumb__arr {
            background-position: (-$fotorama-thumb-arrow * 2) (-$fotorama-thumb-arrow);
        }
    }
}

.fotorama__nav-wrap--vertical {
    .fotorama__thumb__arr--left,
    .fotorama__thumb__arr--right {
        height: $fotorama-thumb-arrow;
        left: 0;
        position: absolute;
        right: 0;
        cursor: pointer;
        z-index: $z-index-10;

        .fotorama__thumb__arr {
            transform: rotate(90deg);
            margin: auto;
            width: $fotorama-thumb-arrow;
        }
    }

    .fotorama__thumb__arr--left {
        @include fotorama-arrow-gradient(top);
        top: 0;

        .fotorama__thumb__arr {
            background-position: (-$fotorama-thumb-arrow) (-$fotorama-thumb-arrow);
        }
    }

    .fotorama__thumb__arr--right {
        @include fotorama-arrow-gradient(bottom);
        bottom: 0;

        .fotorama__thumb__arr {
            background-position: (-$fotorama-thumb-arrow * 2) (-$fotorama-thumb-arrow);
        }
    }
}

.fotorama__wrap--only-active {
    .fotorama__stage,
    .fotorama__nav {
        max-width: 99999px;
    }

    .fotorama__stage__frame {
        visibility: hidden;
    }

    .fotorama__stage__frame.fotorama__active {
        visibility: visible;
    }
}

.fotorama__thumb__arr {
    @extend .fotorama-sprite;
    background-size: 300%;
    font-size: 0.001px;
    padding-bottom: $fotorama-thumb-arrow;
}

.magnify-fullimage {
    display: none;
}

.gallery-placeholder {
    .loading-mask {
        padding: 0 0 50%;
        position: absolute;
    }

    .loader img {
        position: absolute;
    }
}

body.fotorama__fullscreen {
    overflow-y: hidden;

    .magnify-fullimage {
        display: inline-block;
    }
}

.fotorama__nav--thumbs {
    .fotorama__nav__frame--thumb.fotorama__nav__frame {
        margin: 0 0 0 2px;
        padding: 0;
    }
    .fotorama__nav__shaft {
        .fotorama__thumb-border {
            margin-top: 0;
            + .fotorama__nav__frame {
                margin: 0;
            }
        }
    }
}