//
// Section Widths on Inner Container
// --------------------------------------

/**
 * The following constrain the inner content containers for the major sections.
 * If you need to make a section full width for instance, use a body class
 * plus the $site-width-full container to reset.
*/

html {
    min-height: 100%;
    /* make sure it is at least as tall as the viewport */
    position: relative;
}

body {
    height: 100%;
    /* force the BODY element to match the height of the HTML element */
}

.page-header {
    .panel.wrapper .panel.header,
    .header-top-banner,
    .header.content {
        @include section-width();
    }
    .header.content {
        @include bp(max-width, $bp-medium) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.nav-sections .nav-sections-items {
    @include section-width();
}

.page-main {
    @include section-width();
}

.breadcrumbs ul.items {
    @include section-width();
}

.page-footer .footer.content {
    @include section-width();
}

// Full Width Layouts
.page-layout-1column-nobreadcrumbs,
.page-layout-1column-fullwidth {

    .page-title-wrapper,
    .page-main {
        @include section-width($site-width-full);
    }
}

// Used by the `mobile-move.js` script if enabled
#header-mobile-container {
    position: relative;
    @include clearfix;
}

// No Breadcrumbs
.page-layout-1column-nobreadcrumbs {
    .breadcrumbs {
        display: none;
    }
}


//
// Columns
// --------------------------------------

$column-full:       100%;
$column-main:       80%;
$column-side:       100% - $column-main;

// Wrapper
.columns {
    @include clearfix;

    .sidebar.sidebar-additional,
    .sidebar.sidebar-main,
    .column.main {
        width: $column-full;
        display: block;
        @include clearfix;
    }

    .column.main {
        padding-bottom: 60px;

        @include bp(min-width, $bp-medium) {
            width: $column-main;
        }
    }

    .sidebar.sidebar-main,
    .sidebar.sidebar-additional {
        @include bp(min-width, $bp-medium) {
            width: $column-side;
        }
    }
}

// 1 Column
.page-layout-1column-nobreadcrumbs,
.page-layout-1column,
.page-layout-checkout {

    .column.main {
        max-width: $column-full;
        width: $column-full;
    }
}

// 2 Column - Left Sidebar
.page-layout-2columns-left {
    @include clearfix;

    .column.main {
        float: right;
    }

    .sidebar.sidebar-main,
    .sidebar.sidebar-additional {
        float: left;
    }
}

// 2 Column - Right Sidebar
.page-layout-2columns-right,
.page-layout-3columns {
    @include clearfix;

    .column.main {
        float: left;
    }

    .sidebar.sidebar-main,
    .sidebar.sidebar-additional {
        float: right;
    }
}

//
// Z-Index Fix - Parent Indexes
// --------------------------------------

// Level 0
.page-main,
.breadcrumbs {
    z-index: 0;
}

// Level 1
.page-footer {
    z-index: 1;
}

// Level 2
.page-header,
.nav-sections {
    z-index: 2;
}

//
// Options List Layouts
// --------------------------------------
dl {
    dt, dd {
        display: inline-block;
    }

    dt {
        &::after {
            content: ':';
            padding-right: 5px;
        }
    }
}