// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________


.giftcard-account {
    .please-wait {
        display: none;
    }

    .form-giftcard-redeem {
        .giftcard.info {
            margin-bottom: $indent__l;
        }

        .fieldset {
            // TO DO - The selector ".abs-forms-margin-small" was not found.
            // @extend .abs-forms-margin-small !optional;
        }
    }
}

.opc-wrapper {
    .form-giftcard-account {
        max-width: 500px;

        .field {
            margin: 0 0 $indent__base;

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .giftcard-account-info {
        margin: 0 0 $indent__base;

        .giftcard-account-number {
            margin: 0 0 $indent__s;
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form-giftcard-redeem {
        // TO DO - The selector ".abs-forms-general-desktop" was not found.
        // @extend .abs-forms-general-desktop !optional;
    }

    .block-balance-giftcard {
        .block-content {
            // TO DO - The selector ".abs-account-block-font-size" was not found.
            // @extend .abs-account-block-font-size !optional;
        }
    }
}
