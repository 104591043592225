//
// Newsletter subscription
// --------------------------------------

.block.newsletter {

    .form.subscribe {
        width: 100%;
    }

    .fieldset {
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {


            &:before {
                position: absolute;
            }

            display: block;
        }
    }

    input {
        padding-left: 35px;
    }
}