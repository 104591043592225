// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding: $indent__l;
$checkout-sidebar-shipping-information__line-height: $checkout-billing-address-details__line-height;

$checkout-sidebar-shipping-information-edit-icon__color: $minicart-icons-color;
$checkout-sidebar-shipping-information-edit-icon__content: $icon-settings;
$checkout-sidebar-shipping-information-edit-icon__font-size: 28px;
$checkout-sidebar-shipping-information-edit-icon__line-height: 28px;
$checkout-sidebar-shipping-information-edit-icon__top: 2px;
$checkout-sidebar-shipping-information-edit-icon__hover__color: $primary__color;

//
//  Common
//  _____________________________________________



//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {
    padding: 0 $checkout-sidebar-shipping-information__padding;

    .shipping-information-title {
        @extend .abs-checkout-title;
        border-bottom: $checkout-step-title__border;
        margin: 0 0 $indent__base;
        position: relative;

        .action-edit {
            @extend .abs-action-button-as-link;
            top: $checkout-sidebar-shipping-information-edit-icon__top;
            @include lib-icon-font(
                $checkout-sidebar-shipping-information-edit-icon__content,
                $_icon-font-size: $checkout-sidebar-shipping-information-edit-icon__font-size,
                $_icon-font-line-height: $checkout-sidebar-shipping-information-edit-icon__line-height,
                $_icon-font-text-hide: true,
                $_icon-font-color: $checkout-sidebar-shipping-information-edit-icon__color,
                $_icon-font-color-hover: $checkout-sidebar-shipping-information-edit-icon__hover__color,
                $_icon-font-color-active: $checkout-sidebar-shipping-information-edit-icon__color
            );
            margin: 0;
            position: absolute;
            right: 0;
        }
    }

    .shipping-information-content {
        line-height: $checkout-sidebar-shipping-information__line-height;
        .actions-toolbar {
            margin-left: 0;
        }
    }

    .ship-to,
    .ship-via {
        margin: 0 0 $indent__base;
    }
}
