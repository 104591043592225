//
// Variables
// ---------------------------------------------



//
// Forgot Password Page
// ---------------------------------------------

.customer-account-forgotpassword {
    .page-main {
        @include section-width($site-width-smallest);
    }

    .field {

        &.note {
            text-align: center;
        }
    }

    .fieldset {
        margin: 0;
    }
}

.captcha-image {
    img {
        width: 100%;
    }
}
.form.password.forget {
    .field {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
}