//
// Variables
// ---------------------------------------------

$filter-inactive-background-color: #b7b7b7;

$filter-margin: 20px;

$filters-wrapper-width:     $column-side;
$filters-products-width:    $column-main;


//
// Filter
// ---------------------------------------------

.block.filter {
    position: relative;

    @include bp(min-width, $bp-medium) {
        width: $filters-wrapper-width;
        overflow: hidden;
        float: left;
    }

    dt, dd {
        display: block;
    }

    dt::after {
        display: none;
    }

    .filter-title {
        text-transform: uppercase;
        letter-spacing: 2.83px;
        font-size: rem(12px);
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        padding: 0;
        border: 0;
        z-index: 1;

        @include bp(min-width, $bp-medium) {
            position: static;
            margin: 0;
        }

        &::after {
            transform: translateY(-50%);
            font-size: rem(20px);
            position: absolute;
            content: '-';
            right: 18px;
            top: 50%;

            @include bp(min-width, $bp-medium) {
                display: none;
            }
        }

        > span {
            @include bp(min-width, $bp-medium) {
                display: none;
            }
        }

        .active-filter-count {
            border-radius: 100%;
            display: block;
            position: absolute;
            right: 18px;
            top: 50%;
            transform: translateY(-50%);
            color: $color-white;
            background: $color-secondary;
            padding: 10px 6px;
            line-height: 0;
            font-size: 12px;
            z-index: 2;

            @include bp(min-width, $bp-medium) {
                display: none;
            }
        }
    }

    .filter-toggle {
        margin-bottom: $filter-margin;
        text-transform: none;
        letter-spacing: 0;
        display: none;

        @include bp(min-width, $bp-medium) {
            display: block;
        }

        .filter-toggle-indicator {
            @include transition(right, $animate-short, $animate-ease);
            background: $filter-inactive-background-color;
            display: inline-block;
            border-radius: 10px;
            font-size: rem(10px);
            color: $color-white;
            padding-left: 10px;
            line-height: 20px;
            position: relative;
            cursor: pointer;
            height: 20px;
            width: 56px;

            &::before {
                content: attr(data-hide-text);
            }

            &::after {
                @include transition(right, $animate-medium, $animate-ease);
                background: $color-white;
                border-radius: 100%;
                position: absolute;
                display: block;
                height: 16px;
                width: 16px;
                right: 2px;
                top: 2px;
                content: ' ';
            }
        }

        label {
            @extend %font-base;
            text-transform: uppercase;
            color: $color-black;
            font-size: rem(14px);
            letter-spacing: 3.31px;
            padding-right: 15px;
        }
    }

    .filter-content {
        background: $color-white;
        position: relative;

        @include bp(min-width, $bp-medium) {
            @include transition(all, $animate-medium, $animate-ease);
            position: static;
            padding-top: 0;
        }

        .filter-options {
            padding: 0 10px;

            @include bp(min-width, $bp-medium) {
                padding: 0;
            }
        }

        .block-subtitle {
            display: none;
        }
    }

    .filter-current {
        background: $color-grey-lighter;
        font-size: rem(12px);
        text-align: center;
        padding: 10px;

        @include bp(min-width, $bp-medium) {
            background: transparent;
            text-align: left;
            padding-left: 0;
        }

        .filter-current-subtitle {
            @extend %font-base-italic;
            margin-bottom: 10px;
            display: block;
        }

        .items {
            @include lib-list-reset-styles();

            .item {
                box-shadow: 0 1px 1px 1px $color-grey-light;
                background-color: $color-primary-blue;
                color: $color-white;
                display: inline-block;
                padding: 5px 10px 8px;
                margin: 0 5px 5px 0;
                border-radius: 5px;
                line-height: 1;

                @include bp(min-width, $bp-medium) {
                    background-color: $color-primary-blue;
                }

                .filter-label {
                    &::after {
                        content: ':';
                    }
                }

                .action.remove {
                    @extend %font-base-bold;
                    color: $color-grey-light;
                    text-decoration: none;
                    font-size: rem(16px);
                    padding-right: 3px;
                    position: relative;
                    line-height: 1;
                    top: 2px;
                }
            }
        }
    }

    .filter-actions {
        background: $color-grey-lighter;
        padding: 0 10px 10px;
        font-size: rem(12px);
        text-align: center;

        @include bp(min-width, $bp-medium) {
            background: transparent;
            text-align: left;
            padding-left: 0;
        }
    }


    //
    // Filter Listings
    // =========================================

    .filter-wrapper {
        border-top: 1px solid $color-grey-lighter;
        padding: 10px 0;

        //@include bp(min-width, $bp-medium) {
        //    margin-bottom: $filter-margin;
        //    padding: 0;
        //    border: 0;
        //}

        &:last-child {
            border-bottom: 1px solid $color-grey-lighter;

            @include bp(min-width, $bp-medium) {
                border-bottom: 0;
            }
        }
    }

    .filter-options-title {
        @extend %font-base-light;
        color: $color-black;
        position: relative;
        padding: 8px 25px;

        //@include bp(min-width, $bp-medium) {
        //    font-weight: $font-bold;
        //    margin-bottom: 15px;
        //    padding: 0;
        //}

        &::before {
            transform: translateY(-50%);
            font-size: rem(18px);
            color: $color-black;
            position: absolute;
            content: '+';
            right: 25px;
            z-index: 1;
            top: 50%;

            //@include bp(min-width, $bp-medium) {
            //    display: none;
            //}
        }

        &.active {
            &::before {
                content: '-';
            }

            ~.filter-options-content {
                display: block;
            }
        }
    }

    .filter-options-content {
        display: none;

        .swatch-attribute {
            margin-bottom: $filter-margin;
        }

        .items {
            margin-bottom: $filter-margin;

            .item {
                padding: 10px 25px;
                font-size: rem(12px);

                @include bp(min-width, $bp-medium) {
                    margin-bottom: 15px;
                    font-size: rem(14px);
                    padding: 0;
                }

                a {
                    text-decoration: none;
                    color: $color-base-light;
                    position: relative;
                    cursor: pointer;

                    &:hover {
                        color: $color-primary;

                        &::before {
                            background-color: $color-primary;
                            border-color: $color-primary;
                        }

                        &::after {
                            display: block;
                        }
                    }

                    &::before {
                        border: 1px solid $color-grey-lighter;
                        background-color: $color-grey;
                        vertical-align: middle;
                        display: inline-block;
                        border-radius: 5px;
                        margin-right: 10px;
                        height: 17px;
                        width: 17px;
                        margin-top: -4px;
                        content: '';
                    }
                }

                .count {
                    vertical-align: text-top;
                    display: inline-block;
                    font-size: rem(11px);
                    margin-left: 2px;
                    opacity: 0.5;

                    &::before {
                        content: '(';
                    }

                    &::after {
                        content: ')';
                    }

                    .filter-count-label {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    //
    // Producers Wrapper
    // =====================================

    & ~ .toolbar-products,
    & ~ .products.wrapper {
        @include bp(min-width, $bp-medium) {
            width: $filters-products-width;
            float: right;
        }
    }




    //
    // When Filter is closed
    // =====================================

    &.collapsed {

        // Products Wrapper
        & ~ .products.wrapper {
            @include bp(min-width, $bp-medium) {
                width: 100%;
                float: none;
            }

            .products-list, .products-grid {
                @include bp(min-width, $bp-medium) {
                    clear: both;
                }
            }
        }

        // Products Toolbar
        & ~ .toolbar-products {
            float: none;
            width: 100%;

            .modes {
                clear: both;
            }
        }

        // Everything else
        .filter-title {
            &::after {
                content: '+';
            }
        }

        .filter-toggle {
            margin-bottom: $filter-margin;

            label {
                color: $color-grey;
            }
        }

        // Filter Toggle
        .filter-toggle-indicator {
            background: $color-primary;
            padding-right: 10px;
            text-align: right;
            padding-left: 0;

            &::before {
                content: attr(data-show-text);
            }

            &::after {
                right: 38px;
            }
        }

        // Filter Content
        .filter-content {
            transform: none;
            display: none;

            @include bp(min-width, $bp-medium) {
                transform: translateX(-100%);
                position: absolute;
                display: block;
            }
        }
    }

    //
    // Has Filter Count Mobile only
    // =========================================

    &.has-count {

        & ~ .products.wrapper {
            .sorter {
                position: absolute;

                @include bp(min-width, $bp-medium) {
                    position: static;
                }
            }
        }

        .filter-content {
            display: block;

            .filter-options {
                display: block;
            }
        }

        &.collapsed {
            .filter-content {
                .filter-options {
                    display: none;
                }
            }
        }
    }
}