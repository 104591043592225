// Search
.block.block-search {
    margin: 20px 0 0;

    @include bp(min-width, $bp-medium) {
        margin: 15px 0 0;
    }

    form {
        position: relative;

        .actions button {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .input-text {
        margin: 0;
        background-color: $color-grey-light;
        border: 0;
        border-radius: 0;
    }

    .control {
        border: none;
    }

    .actions {
        
        button {
            width: 37px;
            height: 37px;

            &:hover {
                background-color: $color-grey-darker;
            }
        }
    }

    .label {
        vertical-align: middle;
        display: inline-block;

        &.active ~ .control {
            border: 0;
            margin: 0;
            @include bp(min-width, $bp-medium) {
                border: none;
                padding: 0;
            }

            input { 
                outline: 0;
            }
        }
    }
}
