//
// Section Width Mixin
//--------------------------------------

$site-width-smallest:   760px;
$site-width-smaller:    960px;
$site-width-small:      1024px;
$site-width:            1200px;
$site-width-large:      1440px;
$site-width-larger:     1600px;
$site-width-full:       100%;

@mixin section-width($width: $site-width, $padding: 20px, $mobile-padding: 3%) {
    @extend %block-center;
    padding-right: $mobile-padding;
    padding-left: $mobile-padding;
    max-width: $width;
    width: 100%;

    @include bp(min-width, $bp-medium, true) {
        padding-right: $padding;
        padding-left: $padding;
    }
}


//
// Clearfix
//--------------------------------------

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


//
//  Transition
//--------------------------------------

@mixin transition($transition-property, $transition-time: '', $method: '') {
    transition: $transition-property $transition-time $method;
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
}

@mixin transition-property($transition-property: all) {
    transition-property: $transition-property;
    -webkit-transition-property: $transition-property;
    -moz-transition-property: $transition-property;
    -ms-transition-property: $transition-property;
    -o-transition-property: $transition-property;
}

@mixin transform($transform-property) {
    -webkit-transform: $transform-property;
    -moz-transform: $transform-property;
    -ms-transform: $transform-property;
    -o-transform: $transform-property;
    transform: $transform-property;
}

@mixin centerDiv($horizontal: 50%, $vertical: 50%) {
    @include transform(translate(-$horizontal, -$vertical));
    position: absolute;
    left: $horizontal;
    top: $vertical;
}


//
//  Mixin Triangles
//--------------------------------------

@mixin triangle($border-top: 50px, $border-right: 50px, $border-bottom: 100px, $border-left: 100px, $colour: $color-black, $direction: down) {
    width: 0;
    height: 0;
    content: '';
    position: absolute;

    @if ($direction != down) {
        border-bottom: $border-bottom solid transparent;
    }
    @if ($direction != up) {
        border-top: $border-top solid transparent;
    }
    @if ($direction != left) {
        border-left: $border-left solid transparent;
    }
    @if ($direction != right) {
        border-right: $border-right solid transparent;
    }

    @if ($direction == down) {
        border-top-color: $colour;
    }
    @if ($direction == up) {
        border-bottom-color: $colour;
    }
    @if ($direction == left) {
        border-right-color: $colour;
    }
    @if ($direction == right) {
        border-left-color: $colour;
    }
}


//
//  Truncate Text
//--------------------------------------

@mixin truncate($width: 100px) {
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


//
//  Responsive Tables
//--------------------------------------

@mixin responsive-tables($breakpoint: $bp-medium, $labels: true) {
    table, thead, tbody, tfoot, tr, td, th {
        display: block;
    }

    //Self clear any floating blocks
    tr {
        &::after {
            content: ' ';
            display: table;
            clear: both;

            @include bp(min-width, $breakpoint) {
                content: none;
            }
        }
    }

    // Reset table styling
    @include bp(min-width, $breakpoint) {
        table {
            display: table;
        }
        thead {
            display: table-header-group;
        }
        tbody {
            display: table-row-group;
        }
        tfoot {
            display: table-footer-group;
        }
        tr {
            display: table-row;
        }
        th, td {
            display: table-cell;
        }
    }

    // Display Labels if true
    @if ($labels == true) {
        th {
            display: none;

            @include bp(min-width, $breakpoint) {
                display: table-cell;
            }
        }

        td:before {
            content: attr(data-th) ": ";
            display: inline-block;
            margin-right: 8px;
            font-weight: 600;

            @include bp(min-width, $breakpoint) {
                display: none;
            }
        }
    }
}


//
// Quick mixin for width / floats / only above a certain bp
// ----------------------------------------------------------
@mixin setFieldWidth($width, $bp: 0, $padding-left: 0, $padding-right: 0) {
    @if ($bp != 0) {
        @include bp(min-width, $bp) {
            width: $width;
            @if ($padding-left != 0) {
                padding-left: $padding-left;
            }
            @if ($padding-right != 0) {
                padding-right: $padding-right;
            }
            float: left;
        }
    }
    @else {
        width: $width;
        @if ($padding-left != 0) {
            padding-left: $padding-left;
        }
        @if ($padding-right != 0) {
            padding-right: $padding-right;
        }
        float: left;
    }
}

//
// Chevron Mixin
//--------------------------------------

@mixin chevron($dir: right, $size: 14px, $side: after) {
    &:#{$side} {
        display: block;
        position: absolute;
        font-size: $size;
        @extend %chevron-#{$dir};
        @content;
    }
}


//
// Text Center Mobile
//--------------------------------------

@mixin text-mobile-center($breakpoint: $bp-small) {
    text-align: center;

    @include bp(min-width, $breakpoint) {
        text-align: left;
    }
}


//
// Placeholder
//--------------------------------------

@mixin placeholderColor($color) {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $color;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $color;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $color;
    }
}