//
// Breadcrumbs
// ---------------------------------------------

.breadcrumbs {
    display: none;

    @include bp(min-width, $bp-medium, true) {
        display: block;
    }

    ul.items {
        @include section-width;
        padding: 15px 0 11px;

        .item {
            @extend %font-base;
            display: inline-block;
            text-transform: uppercase;
            margin-right: 5px;
            padding: 0;
            line-height: 1.2;

            &::after {
                content: '\e608';
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: -2px;
                color: $color-grey-dark;
                font-family: 'icons-blank-theme';
                font-size: 1.8rem;
                width: 17px;
                margin-left: -10px;
            }


            strong, a {
                @extend %font-base;
                text-decoration: none;
                color: $color-primary-blue;
                letter-spacing: 0;
                font-size: 12px;
                font-weight: $font-weight__bold;
            }

            a {
                padding-right: 5px;
            }

            strong {
                color: $color-grey-dark;
            }
        }

        .item:last-of-type {
            margin-right: 0;

            &::after {
                content: "";
            }
        }
    }
}