//
// Variables
// --------------------------------------

$animate-short:     0.1s;
$animate-medium:    0.3s;
$animate-long:      0.6s;
$animate-ease:      ease;


// --------------------------------------------------------------------------
// Purpose:
// Used for background images changing opacity, such as a modal overlay.
// --------------------------------------------------------------------------

@mixin animate-overlay($speed: $animate-long, $ease: $animate-ease) {
    animation-timing-function: $ease;
    animation-name: overlay#{$speed};
    animation-fill-mode: forwards;
    animation-duration: $speed;
    animation-delay: 0s;

    @keyframes overlay#{$speed} {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}


// --------------------------------------------------------------------------
// Purpose:
// Alternative for when transition works better than @keyframes
// --------------------------------------------------------------------------

@mixin animate-overlay-transition($div, $speed-opacity: $animate-long, $ease: $animate-ease) {
    transition: opacity $speed-opacity $ease, visibility $speed-opacity $ease;
    visibility: hidden;
    opacity: 0;

    #{$div} {
        visibility: visible;
        opacity: 1;
    }
}


// --------------------------------------------------------------------------
// Purpose:
// Used for items that need to grow in size, such as a modal container.
// --------------------------------------------------------------------------

@mixin animate-scale($scale: 0.95, $speed-scale: $animate-long, $speed-fade: $animate-medium, $ease: $animate-ease) {
    animation-duration: $speed-scale, $speed-fade;
    animation-timing-function: $ease;
    animation-name: scale, fadeScale;
    animation-fill-mode: forwards;
    animation-delay: 0s;

    @keyframes scale {
        from { transform: scale3d($scale, $scale, $scale); }
        to { transform: scale3d(1, 1, 1); }
    }

    @keyframes fadeScale {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}


// --------------------------------------------------------------------------
// Purpose:
// Items that need a slight drop down effect such as a drop downs.
// --------------------------------------------------------------------------

@mixin animate-slide-down(
    $slide-amount: -5px,
    $fade: true,
    $speed-slide: $animate-long,
    $speed-fade: $animate-medium,
    $ease: $animate-ease
) {
    // Number appended to create unique animation names per instance
    @if $fade {
        animation-name: slideDown#{$slide-amount}, fadeDown#{$slide-amount};
    } @else {
        animation-name: slideDown#{$slide-amount};
    }
    animation-duration: $speed-slide, $speed-fade;
    animation-timing-function: $ease;
    animation-fill-mode: forwards;
    animation-delay: 0s;

    @keyframes slideDown#{$slide-amount} {
        from { transform: translate3d(0, $slide-amount, 0); }
        to { transform: translate3d(0, 0, 0); }
    }

    @if $fade {
        @keyframes fadeDown#{$slide-amount} {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    }
}


// ----------------------------------------------------------------------------
// Purpose:
// Items that need a slight shift from the left, such as inner modals or trays.
// ----------------------------------------------------------------------------

@mixin animate-slide-left(
    $slide-amount: -5px,
    $delay: 0s,
    $fade: true,
    $speed-slide: $animate-long,
    $speed-fade: $animate-medium,
    $ease: $animate-ease
) {
    // Number appended to create unique animation names per instance
    @if $fade {
        animation-name: slideLeft#{$slide-amount}, fadeLeft#{$slide-amount};
    } @else {
        animation-name: slideLeft#{$slide-amount};
    }
    animation-duration: $speed-slide, $speed-fade;
    animation-timing-function: $ease;
    animation-fill-mode: forwards;
    animation-delay: $delay;

    @keyframes slideLeft#{$slide-amount} {
        from { transform: translate3d($slide-amount, 0, 0); }
        to { transform: translate3d(0, 0, 0); }
    }

    @if $fade {
        @keyframes fadeLeft#{$slide-amount} {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    }
}


// --------------------------------------------------------------------------
// Purpose:
// Useful for menu dropdown where transition is needed for the hover out state
// --------------------------------------------------------------------------

@mixin animate-hover-menu(
    $child-selector,
    $slide: -5px,
    $speed-slide: $animate-long,
    $speed-fade: $animate-medium,
    $ease: $animate-ease

) {
    // Starting Point
    #{$child-selector} {
        transition: transform $speed-slide $ease, opacity $speed-fade $ease, visibility $speed-fade $ease;
        transform: translate3d(0, $slide, 0);
        opacity: 0;
    }

    // On Interaction
    &:hover #{$child-selector} {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}


// ----------------------------------------------------------------------------
// Purpose:
// Used for hover states when you want something to hold in place until you stop hovering.
// ----------------------------------------------------------------------------

@mixin animate-hover-icon($child-selector, $hover-amount: -3px, $speed: $animate-short, $ease: $animate-ease) {
    #{$child-selector} {
        transition: transform $speed $ease;
    }

    &:hover #{$child-selector} {
        transform: translate3d(0,$hover-amount,0);
    }
}


// ----------------------------------------------------------------------------
// Purpose:
// General transition so you don't need to remember the animate variables.
// ----------------------------------------------------------------------------

@mixin animate-transition($property: all, $speed: $animate-short, $ease: $animate-ease) {
    transition: $property $speed $ease;
}


// ----------------------------------------------------------------------------
// Purpose:
// Mobile Menu Slide Out
// ----------------------------------------------------------------------------

@mixin animate-menu-mobile($position, $slide: $animate-medium, $delay: 0) {
    animation-timing-function: $animate-ease;
    animation: slide $slide forwards;
    animation-delay: $delay;
    left: -$position;

    @keyframes slide {
        100% { left: 0; }
    }
}