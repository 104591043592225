// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//    Common
//--------------------------------------
.table-downloadable-products {
    .product-name {
        font-weight: $font-weight__regular;
        margin-right: 15px;
    }
    .action.download {
        @include lib-icon-font(
            $_icon-font-content: $icon-download,
            $_icon-font-margin: 0 5px 0 0
        );
    }
}
.page-product-downloadable {
    .product-add-form {
        @extend .abs-add-clearfix;
        clear: both;
        margin-bottom: $indent__m;
    }
    .product-options-bottom {
        .field.qty {
            display: none;
        }
    }
    .product-options-wrapper {
        .fieldset {
            .legend.links-title {
                @extend .abs-visually-hidden;
            }
        }
    }
}
.samples {
    &-item-title {
        font-size: 22px;
        font-weight: 600;
        margin: 0 0 $indent__s;
    }
    &-item {
        margin: 0 0 $indent__s;
    }
}
.field.downloads {
    margin-bottom: $indent__s;
    .page-product-downloadable .product-add-form .product-options-wrapper & > .control {
        width: 100%;
    }
    .price-container {
        display: inline;
        white-space: nowrap;
        &:before {
            content: " + ";
            display: inline;
        }
    }
    .price-excluding-tax {
        display: inline;
        &:before {
            content: " (" attr(data-label) ": ";
        }
        &:after {
            content: ")";
        }
    }
    .price-including-tax {
        display: inline;
        font-weight: $font-weight__semibold;
        font-size: 14px;
    }
    .field.choice {
        box-sizing: border-box;
        border-bottom: 1px solid $color-gray92;
        padding-bottom: $indent__s;
        margin-bottom: $indent__s;
        // TO DO - The selector ".clearer" was not found.
        // @extend .clearer !optional;
        width: 100%;
        position: relative;
        z-index: 1;
        input {
            position: absolute;
            left: 0;
            top: 2px;
        }
        padding-left: 25px;
    }
    .field {
        &.downloads-all {
            padding-left: 0;
            border-bottom: 0;
        }
    }
    .sample.link {
        float: right;
    }
    &-all {
        .label {
            @include lib-link();
            cursor: pointer;
        }
        input {
            opacity: 0;
        }
    }
    &.downloads-no-separately {
        .field.choice {
            padding-left: 0;
        }
    }
}
//
//    Mobile
//--------------------------------------
@include max-screen($screen__s) {
    .page-product-downloadable .product-options-wrapper {
        margin-bottom: $indent__m;
    }
    .table-downloadable-products {
        .product-name {
            display: block;
            margin-bottom: $indent__xs;
        }
        .col.title {
            &:before {
                @extend .abs-visually-hidden-mobile;
            }
        }
    }
    .page-product-downloadable .product-info-price ~ *:last-child:after {
        border-bottom: 1px $color-gray-light5 solid;
        content: '';
        display: block;
        height: 0;
        overflow: hidden;
        width: auto;
        margin: $indent__m -$indent__s;
    }
}
