// Minicart
.minicart-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    @include bp(max-width, $bp-medium) {
        padding-right: 3%;
    }

    @include bp(min-width, $bp-medium) {
        margin: 7px 0 0 20px;
    }

    .block-minicart {
        @include animate-slide-down();
    }

    .action.showcart {
        vertical-align: middle;
        display: inline-block;
        text-align: center;

        &::before {
            content: '\e900'!important;
            font-family: 'icomoon'!important; 
            color: $color-black;
        }

        .text {
            @extend .show-for-desktop;
            display: none;
            color: $color-black;

            @include bp(min-width, $bp-medium) {
                display: block;
            }
        }
    }
}