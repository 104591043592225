//
// Variables
// ---------------------------------------------

$nav-border:            1px solid $color-grey;
$nav-item-pad:          20px;
$nav-submenu-width:     160px;
$nav-mobile-width:      percentage(265px/$bp-xxxsmall);

//
// Default Setup
// ---------------------------------------------

//*** Top Level ***//
.nav-sections {
    position: relative;
    display: none;
    background-color: $color-primary;

    @include clearfix;

    @include bp(min-width, $bp-medium) {
        display: block;
    }

    .section-items {
        padding: 0;
    }

    .navigation > ul {
        padding: 0;
        margin: 0;

        @include clearfix;

        @include bp(min-width, $bp-medium) {
            display: flex;
            align-items: center;
        }

        // Reset
        ul, 
        li {
            padding: 0;
            margin: 0;

            a {
                text-decoration: none;
            }
        }

        // Top Level Nav
        > li {
            position: relative;
            display: block;
            float: left;

            > a,
            a.level-top {
                color: $color-white;
                position: relative;
                padding: 15px 8px 13px;
                display: block;
                line-height: 20px;
                font-weight: 700;
            }
        }

        // Submenu
        .submenu {
            background: $color-white;
            border: $nav-border;
            z-index: $z-nav;

            @include bp(min-width, $bp-medium) {
                width: $nav-submenu-width;
                position: absolute;
            }

            li, 
            a {
                display: block;
                width: 100%;
                margin: 0;
            }

            li { // for ul positioning
                position: relative;
            }

            a {
                padding: 10px $nav-item-pad;
                color: $color-primary;
                line-height: 20px;

                &:hover {
                    background: $color-grey-dark;
                    color: $color-white;
                }
            }

            &.level0 {
                top: 100%;
                left: 0;

                ul { // All lists level1 and below
                    left: 100%;
                    top: 0;
                }
            }
        }


        //*** Styling to Animate Menu ***//

        li {
            @include bp(min-width, $bp-medium) {
                @include animate-hover-menu("> .submenu");
            }

            .submenu {
                @include bp(min-width, $bp-medium) {
                    visibility: hidden;
                    display: initial;
                }
            }

            &:hover > .submenu {
                @include bp(min-width, $bp-medium) {
                    visibility: visible;
                }
            }
        }
    }


    //*** Hidden Menu ***//

    .nav-sections-items .section-item-title,
    .navigation-toggle {
        display: none;
    }
}
#mobile-nav-only {
    @include bp(min-width, $bp-medium) {
        display: none;
    }
    a {
        padding: 20px;
    }
}


//
// Mobile Nav Open
// ---------------------------------------------

body {
    @include animate-transition(left);
    left: 0;
}

.nav-opened {

    body {
        @extend %no-scroll;
        left: $nav-mobile-width;

        &::before {
            background: $color-black;
            z-index: $z-overlay;
            position: absolute;
            display: block;
            opacity: 0.25;
            content: "";
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
        }
    }

    .nav-sections {
        @include animate-menu-mobile($nav-mobile-width);
        box-shadow: 0 10px 15px 0 rgba($color-black, 0.25);
        background: $color-white;
        width: $nav-mobile-width;
        overflow-y: auto;
        z-index: $z-nav;
        position: fixed;
        display: block;
        height: 100%;
        border: none;
        top: 0;

        .navigation {
            background: $color-primary;
        }

        .navigation li {
            border-bottom: 1px solid $color-grey;
            position: relative;
            display: block;
            width: 100%;

            > a.level-top {
                padding: 20px;
            }

            > ul.submenu {
                display: none;
                border: none;

                &.opened {
                    display: block;
                }

                li {
                    //background: $color-primary;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    .submenu li {
                        background: $color-grey-dark;

                        a {
                            color: $color-white;
                        }
                    }
                }
            }

            &.parent {
                > a > span {
                    @include lib-icon-font(
                            $_icon-font-content : $icon-down,
                            $_icon-font-size    : 42px,
                            $_icon-font-position: after,
                            $_icon-font-display : block
                    );
                    &::after {
                        //content: $icon-down;
                        @include animate-transition();

                        //font-family: $icon-font




                        //position: absolute;
                        //right: 7px;


                        display: inline-block;
                        float: right;
                    }
                }

                &.opened > a > span::after {
                    transform: rotate(180deg) rotateZ(0deg);
                }
            }
        }
    }
}


//
// Toggle Nav
// ---------------------------------------------

.nav-toggle {
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    float: left;
    padding-left: 3%;

    @include bp(min-width, $bp-medium) {
        display: none;
    }

    &::before {
        @extend %icon-bars;
        font-size: rem(16px);
    }

    span {
        display: none;
    }
}
