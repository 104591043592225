//
// Variables
// ---------------------------------------------

//
// Placeholders
// ---------------------------------------------

%account-title {
    @extend %font-base-bold;
    border-bottom: 1px solid $color-grey;
    color: $color-black;
    font-size: rem(20px);
    letter-spacing: 0;
    padding-bottom: 16px;
    margin-bottom: 25px;
    line-height: 1.785;
}

//
// Imports
// ---------------------------------------------

@import 'module/account';
@import 'module/login';
@import 'module/logout';
@import 'module/register';
@import 'module/password';
@import 'module/printorder';