//
// Catalog Listing
// --------------------------------------
.products-grid {

    .product-item {
        text-align: center;

        .product-item-details {
            position: relative;
            padding-bottom: 82px;
        }
        .product-item-link {
            color: $color-black;
            overflow: hidden;
            display: block;
            min-height: 80px;
            padding: 0 5px;
        }
        .price-box,
        .product-item-inner {
            position: absolute;
            width: 100%;
            left: 0;
        }
        .price-box {
            bottom: 50px;
        }
        .product-item-inner {
            bottom: 0;
        }
    }
}