// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//    Common
//--------------------------------------

.load.indicator {
    @include lib-loader();
    background-image: url('#{$loadGif}');
    position: absolute;

    & > span {
        display: none;
    }
}

.loading-mask {
    @include lib-loading-mask();
    background: rgba(255, 255, 255, 0.5);

    .loader {
        & > img {
            @include lib-loading-mask();
        }

        & > p {
            display: none;
        }
    }
}

body {
    & > .loading-mask {
        z-index: $loader-overlay__z-index;
    }
}

._block-content-loading {
    position: relative;
}
