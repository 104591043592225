// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Icons variables
//  _____________________________________________

$icon__position: before;
$icon__text-hide: inherit;
$icon__height: 26px;
$icon__width: 26px;
$icon__margin: inherit;
$icon__vertical-align: middle;

$icon-image__position-x: 0;
$icon-image__position-y: 0;

$icon-sprite__position-x: 0;
$icon-sprite__position-y: 0;
$icon-sprite__grid: 26px;

$icon-font: $icons__font-name;
$icon-font__position: $icon__position;
$icon-font__text-hide: $icon__text-hide;
$icon-font__margin: $icon__margin;
$icon-font__size: inherit;
$icon-font__line-height: $icon-font__size;
$icon-font__color: inherit;
$icon-font__color-hover: inherit;
$icon-font__color-active: inherit;
$icon-font__vertical-align: $icon__vertical-align;
$icon-font__display: inline-block;

$icon-calendar__font-size: 40px;

//
//  Variables for icons-blank-theme
//  ---------------------------------------------

// ToDo UI: move it to blank theme variables
$icon-wishlist-full: '\e600';
$icon-wishlist-empty: '\e601';
$icon-warning: '\e602';
$icon-update: '\e603';
$icon-trash: '\e604';
$icon-star: '\e605';
$icon-settings: '\e606';
$icon-pointer-down: '\e607';
$icon-next: '\e608';
$icon-menu: '\e92c';
$icon-location: '\e60a';
$icon-list: '\e60b';
$icon-info: '\e60c';
$icon-grid: '\e60d';
$icon-comment-reflected: '\e60e';
$icon-collapse: '\e60f';
$icon-checkmark: '\e610';
$icon-cart: '\e611';
$icon-calendar: '\e612';
$icon-arrow-up: '\e613';
$icon-arrow-down: '\e614';
$icon-search: '\e615';
$icon-remove: '\e616';
$icon-prev: '\e617';
$icon-pointer-up: '\e618';
$icon-pointer-right: '\e619';
$icon-pointer-left: '\e61a';
$icon-flag: '\e61b';
$icon-expand: '\e61c';
$icon-envelope: '\e61d';
$icon-compare-full: '\e61e';
$icon-compare-empty: '\e61f';
$icon-comment: '\e620';
$icon-up: '\e621';
$icon-down: '\e622';
$icon-help: '\e623';
$icon-arrow-right-thin: '\e624';
$icon-arrow-left-thin: '\e625';
$icon-arrow-down-thin: '\e626';
$icon-account: '\e627';
$icon-gift-registry: '\e628';
$icon-present: '\e629';
$icon-arrow-up-thin: '\e633';
$icon-baseline-shopping_basket: '\e900';
$icon-baseline-cloud_upload: '\e901';
