// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$block-auth__dropdown__padding: $indent__m;
$block-auth__dropdown__background-color: $color-white;
$block-auth__or-label__size: 36px;
$block-auth__width: 0;
$block-auth__border: 1px solid $color-gray-light3;

//
//  Common
//  _____________________________________________


.authentication-dropdown {
    box-sizing: border-box;

    .modal-inner-wrap {
        padding: $block-auth__dropdown__padding;
    }
}
.authentication-wrapper {
    float: right;
    margin-top: -1.5 * $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 1;

    ._has-auth-shown & {
        z-index: $modal__z-index;
    }
}

.action-auth-toggle {
    @extend .abs-action-button-as-link;
}

.block-authentication {
    .block-title {
        font-size: headings($heading-h3, font-size);
        border-bottom: 0;
        margin-bottom: $indent__m;

        strong {
            font-weight: $font-weight__light;
        }
    }

    .field {
        .label {
            font-weight: $font-weight__regular;
        }
    }

    .actions-toolbar {
        margin-bottom: $indent__xs;

        & > .secondary {
            padding-top: $indent__m;
            text-align: left;
        }
    }

    .action.action-register,
    .action.action-login {
        @extend .abs-button-l;
    }

    .block[class] {
        margin: 0;

        ul {
            list-style: none;
            padding-left: $indent__s;
        }

        .field {
            .control,
            .label {
                float: none;
                width: auto;
            }
        }

        & + .block {
            border-top: 1px solid $color-gray-light5;
            margin-top: $indent__xl;
            padding-top: $indent__xl;
            position: relative;

            &:before {
                height: $block-auth__or-label__size;
                line-height: $block-auth__or-label__size - 2px;
                margin: -($block-auth__or-label__size / 2 + 1px) 0 0 (-$block-auth__or-label__size / 2);
                min-width: $block-auth__or-label__size;
                background: $color-white;
                border-radius: 50%;
                border: 1px solid $color-gray-light5;
                box-sizing: border-box;
                color: $color-gray-light5;
                content: attr(data-label);
                display: inline-block;
                left: 50%;
                letter-spacing: normal;
                padding: 0 0.2rem;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                top: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .authentication-dropdown {
        background-color: $block-auth__dropdown__background-color;
        border: $block-auth__border;
        position: absolute;
        text-align: left;
        top: 100%;
        transform-origin: 0 0;
        transform: scale(1, 0);
        transition: transform linear 150ms, visibility 150ms linear 150ms;
        visibility: hidden;
        width: 100%;

        &._show {
            z-index: $dropdown-list__z-index;
            transform: scale(1, 1);
            transition: transform linear 150ms, visibility 150ms linear 0;
            visibility: visible;
        }
    }

    .authentication-wrapper {
        @include lib-column-width($checkout-sidebar__columns);
        text-align: right;
    }

    .block-authentication {
        .block-title {
            font-size: headings($heading-h2, font-size);
            border-bottom: 0;
            margin-bottom: $indent__m;
        }

        .actions-toolbar {
            & > .primary {
                display: inline;
                margin-right: 0;
                .action {
                    margin-right: 0;
                }
            }

            & > .secondary {
                float: left;
                margin-right: 32px;
                padding-top: 16px;
            }
        }
    }

    .popup-authentication {
        .modal-inner-wrap {
            min-width: $screen__m;
            width: 60%;
        }

        .block-authentication {
            display: flex;
            flex-direction: row;
            border-top: 1px solid $color-gray-light5;
        }

        .block[class],
        .form-login,
        .fieldset,
        .block-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .block[class] {
            box-sizing: border-box;
            float: left;
            padding: $indent__s $indent__l 0 0;
            width: 50%;

            & + .block {
                border-left: 1px solid $color-gray-light5;
                border-top: 0;
                margin: 0;
                padding: $indent__s 0 0 $indent__xl;

                &::before {
                    left: 0;
                    top: 50%;
                }
            }
        }

        .actions-toolbar {
            margin-bottom: 0;
            margin-top: auto;
        }
    }
}
