// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base;
$checkout-sidebar__margin__xl: 46px;
$checkout-sidebar__columns: 4;

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
        margin: $checkout-sidebar__margin__xl 0 $checkout-sidebar__margin;
        @include lib-layout-column(2, 2, $checkout-sidebar__columns);
    }
}
#opc-sidebar {
    .totals-tax-summary {
        display: none;
    }
    .totals-tax-details {
        display: table-row;
        border: 0;
    }
}
