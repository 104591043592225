// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//    Common
//--------------------------------------
.form.send.friend {
    @extend .abs-add-fields;

    @include bp(min-width, $screen__m) {
        width: 60%;
    }

    .fieldset {
        .field {
            @include bp(min-width, $screen__m) {
                width: 45%;
            }
        }

        .field.text {
            @include bp(min-width, $screen__m) {
                width: 90%;
            }
        }
    }
}

.action.mailto.friend {
    @include lib-icon-font(
        $_icon-font-content       : $icon-envelope,
        $_icon-font-size          : 28px,
        $_icon-font-line-height   : 28px,
        $_icon-font-vertical-align: top
    );

    span {
        line-height: 28px;
        vertical-align: top;
        display: inline-block;
    }
}