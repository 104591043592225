// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________



.map-popup-wrapper {
    &.popup {
        .action {
            &.close {
                top: $popup-button-close__position-top;
            }
        }
    }
}

.map-popup {
    @include lib-popup(
        $_popup-margin-top     : $indent__s,
        $_popup-margin-left    : inherit,
        $_popup-content-margin : inherit,
        $_popup-position-bottom: inherit,
        $_popup-position-right : inherit,
        $_popup-position       : absolute
    );
    .map-info-price {
        @extend .abs-add-clearfix;
        margin: 0 0 $indent__base;

        .price-box {
            margin: 0 0 $indent__base;

            .label {
                &:after {
                    content: ": ";
                }
            }
        }
    }

    .action {
        &.primary {
            margin-right: $indent__s;
        }
    }

    .map-msrp {
        margin-bottom: $indent__s;
    }

    .actual-price {
        margin: 3px 0 15px;
    }

    .popup-header {
        display: none;
    }
}

.map-old-price {
    text-decoration: none;

    .price-wrapper {
        text-decoration: line-through;
    }
}

.map-old-price,
.product-item .map-old-price,
.product-info-price .map-show-info {
    &:not(:last-child) {
        margin-right: $indent__s;
    }
}

.map-form-addtocart {
    display: inline-block;
    text-align: center;

    img {
        display: block;
        margin: 0 auto;
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .map-popup {
        max-width: 100%;
    }

    .map-old-price,
    .map-show-info {
        display: inline-block;

        &:not(:last-child) {
            margin-bottom: $indent__s;
        }
    }
}
