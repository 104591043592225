//
// Button Styling
// --------------------------------------

button, .btn, a.btn, %btn {
    @include transition(all, 0.3s, ease);
    @extend %font-base-bold;
    background-color: $color-primary;
    border: 1px solid $color-primary;
    display: inline-block;
    line-height: 1;
    font-size: 14px;
    color: $color-white;
    text-decoration: none;
    text-align: center;
    padding: 13px;
    width: auto;

    &:hover, &:focus {
        background-color: $color-primary-dark;
        border: 1px solid $color-primary-dark;
        text-decoration: none;
        color: $color-white;
    }


    //
    // Color Options
    // --------------------------------------

    &-black {
        background-color: $color-black;
        border: 1px solid $color-black;
        color: $color-white;

        &:hover, &:focus {
            background-color: $color-black-dark;
            border: 1px solid $color-black-dark;
            color: $color-white;
        }
    }

    &-white {
        background-color: $color-white;
        border: 1px solid $color-white;
        color: $color-primary;

        &:hover, &:focus {
            background-color: $color-offwhite;
            border: 1px solid $color-offwhite;
            color: $color-primary;
        }
    }


    //
    // Style Options
    // --------------------------------------

    &-ghost {
        border: 1px solid $color-primary;
        background-color: transparent;
        color: $color-primary;

        &:hover, &:focus {
            border: 1px solid $color-primary-dark;
            background-color: transparent;
            color: $color-primary-dark;
        }
    }

    &-ghost-white {
        border: 1px solid $color-white;
        background-color: transparent;
        color: $color-white;

        &:hover, &:focus {
            border: 1px solid $color-white;
            background-color: transparent;
            color: $color-white;
        }
    }


    //
    // Size Options
    // --------------------------------------


    &-large {
        font-size: 24px;
        padding: 21px 0;
        width: 100%;

        @include bp(min-width, $bp-medium, true) {
            padding: 12px 20px;
            width: auto;
        }
    }


    //
    // Reset to Look like a link
    // --------------------------------------

    &-link {
        @extend a;
        background: none;
        border: none;
        padding: 0;
        display: inline;
        font-family: inherit;
        font-size: inherit;
        font-weight: $font-normal;
        letter-spacing: inherit;
        vertical-align: baseline;
        color: $color-primary;

        &:hover, &:focus {
            background: none;
            color: $color-primary-dark;
            border: none;
        }
    }
}