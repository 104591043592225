// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

$legend-border-bottom: 1px solid $color-gray-light6;
$form-field__vertical-indent__desktop: 29px;
$form-calendar-icon__color: $primary__color;
$form-field__vertical-indent: $indent__base;

//
//    Common
//--------------------------------------

.fieldset {
    @include lib-form-fieldset();
    margin: 0 0 20px;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0;
    }
    & > .field,
    & > .fields > .field {
        margin-bottom: 20px;
        float: left;
        width: 50%;
        padding: 0 5px;
        @include bp(max-width, $bp-medium) {
            width: 100%;
            padding: 0;
        }

        &.no-label {
            > .label {
                @include lib-visually-hidden();
            }
        }

        &.choice {
            .label {
                font-weight: normal;
                display: inline;
            }
        }

        .label {
            @extend %font-base-bold;
            text-transform: uppercase;
            margin: 0 0 10px 0;
            padding: 0;
            display: inline-block;

            .column:not(.main) & {
                font-weight: normal;
            }
        }

        &.note {
            width: 100%;
        }
    }

    fieldset.field {
        border: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        .field {
            margin-bottom: 25px;
            float: left;
            width: 50%;
            padding: 0 5px;
            @include bp(max-width, $bp-medium) {
                width: 100%;
                padding: 0;
            }
        }
    }

    .message.info {
        clear: both;
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}


input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"],
textarea {
    @extend %font-base;
    height: auto;
    width: 100%;
    padding: 9px 13px;
    font-size: 14px;
    border-color: $color-grey;
    border-radius: 0;
    background-color: $color-offwhite;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: inherit;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: inherit;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: inherit;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: inherit;
    }

    &:focus {
        box-shadow: none;
        outline: 1px solid $color-grey;
    }

    &.mage-error {
        border: 1px solid $color-error;
    }
}

.field {
    &.date {
        .time-picker {
            white-space: nowrap;
            margin-top: $indent__s;
            display: inline-block;
        }
    }

    .message {
        &.warning {
            margin-top: $indent__s;
        }
    }
}

@mixin select-styling() {
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 9px 20px 9px 13px;
    min-width: 40px;
    background-color: $color-offwhite;
    background-image: url('../images/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 55%;
    background-size: 10px;
    font-size: 14px;
    cursor: pointer;
    appearance: none;
    color: inherit;

    &::-ms-expand {
        display: none;
    }

    .lt-ie10 & {
        background-image: none;
        padding-right: 4px;
    }

    &:not([disabled]):focus {
        box-shadow: none;
        outline: 1px solid $color-grey;
    }

    &:hover, &:focus {
        background-color: $color-white;
    }
}

select {
    @include select-styling();
}

div.mage-error[generated] {
    @extend %font-base;
    margin-top: 7px;
    font-size: 12px;

    &::before {
        content: '*';
        line-height: 1;
        vertical-align: baseline;
        font-size: 12px;
    }
}

// TEMP
.field .tooltip {
    @include lib-tooltip(right);

    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {
    height: auto;
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

.hasDatepicker {
    + .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content    : $icon-calendar,
            $_icon-font-color      : $primary__color__lighter,
            $_icon-font-size       : $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display    : inline-block,
            $_icon-font-text-hide  : true
        );
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

//
//    Sidebar forms
//--------------------------------------
.sidebar {
    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}