//
// Variables
// ---------------------------------------------


//
// Page Header Container
// ---------------------------------------------

.page-header {
    background: $color-grey-lighter;
    z-index: $z-nav + 1;
    position: relative;
    padding: 0 0 20px;
    margin-bottom: 60px;
    @include bp(max-width, $bp-medium) {
        padding: 0;
    }

    @include bp(min-width, $bp-medium) {
        margin-bottom: 0;
    }

    @include clearfix;

    // Logo
    .logo {
        vertical-align: middle;
        display: inline-block;
        float: left;
        width: 40%;
        max-width: 188px;

        @media (min-width: $screen__m) {
            width: 25%;
            max-width: 300px;
        }

        img {
            width: 100%;

            @include bp(min-width, $bp-medium) {
                max-width: none;
                margin: 0;
            }
        }
    }

    // Header Content
    .header.content {
        position: relative;
        
        @include bp(max-width, $bp-medium) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .panel.wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: $color-primary-blue;
        margin-bottom: 20px;
        font-size: 0.8rem;

        a,
        span {
            color: $color-white;
        }

        .panel.header {

            > a {
                margin: 5px 0 5px 10px;
                float: right;
            }
        }
    }

    .switcher {

        .label {
            @extend .abs-visually-hidden;
        }

        &-trigger {
            display: flex;
            margin: 5px 0;

            display: inline-block;
            position: relative;
            @include lib-icon-font($_icon-font-content : $icon-down,
                $_icon-font-size : 2.4rem,
                $_icon-font-color: $color-white,
                $_icon-font-position: after,
                $_icon-font-display : block,
                $_icon-font-margin: 0,
                $_icon-font-line-height: 0.6);

            &:hover {
                cursor: pointer;

                &::after {
                    color: $color-white;
                }
            }
            
            &::after {
                margin-left: -8px;
            }

            span {
                text-decoration: underline;
                display: inline-block;
            }
        }

        &-dropdown {
            margin: 0;
            padding: 0;
            list-style: none none;
            background: #fff;
            border: 1px solid #bbb;
            min-width: 160px;
            z-index: 100;
            box-sizing: border-box;
            display: none;
            position: absolute;
            box-shadow: 0 3px 3px rgba(0, 0, 0, .15);

            a {
                padding: 10px;
            }
        }

        &-options {

            &.active {

                .switcher-trigger {
                    @include lib-icon-font-symbol($_icon-font-content: $icon-up,
                        $_icon-font-position: after);
                }
                
                .switcher-dropdown {
                    display: block;

                    a {
                        color: $color-black;
                    }
                }
            }
        }
    }
}
