//
// Fonts
//--------------------------------------

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900&display=swap');

//
//   As the child themes were not originally planned, we are
//   switching out fonts without changing the variables names
//   to simplify the process.
//

$font-base:     'Roboto', sans-serif;
$font-title:    $font-base;
$font-alt-1:    $font-base;

//
// Font Weights
//--------------------------------------

$font-thin:         100;
$font-light:        300;
$font-normal:       400;
$font-semibold:     500;
$font-bold:         700;
$font-black:        900;

//
// Font Sizes
//--------------------------------------

$font-size-base:      14px;

@function rem($size) {
    $remSize: $size / $font-size-base;
    @return #{$remSize}rem;
}


//
// Font Helpers
//--------------------------------------

//*** Font Base ***//
%font-base {
    font-family: $font-base;
    font-weight: $font-normal;
}

%font-base-italic {
    font-family: $font-base;
    font-weight: $font-normal;
    font-style: italic;
}

%font-base-light {
    font-family: $font-base;
    font-weight: $font-light;
}

%font-base-bold {
    font-family: $font-base;
    font-weight: $font-bold;
}


//*** Font Title ***//
%font-title {
    font-family: $font-title;
    font-weight: $font-normal;
}

%font-title-italic {
    font-family: $font-title;
    font-weight: $font-normal;
    font-style: italic;
}

%font-title-light {
    font-family: $font-title;
    font-weight: $font-light;
}

%font-title-semibold {
    font-family: $font-title;
    font-weight: $font-semibold;
}

%font-title-bold {
    font-family: $font-title;
    font-weight: $font-bold;
}


//*** Font Alt 1 ***//
%font-alt1 {
    font-family: $font-base;
    font-weight: $font-normal;
}

%font-alt1-italic {
    font-family: $font-base;
    font-weight: $font-normal;
    font-style: italic;
}


// Client Classes

.font-name-base {
    @extend %font-base;

    &-bold {
        @extend %font-base-bold;
    }

    &-italic {
        @extend %font-base-italic;
    }
}

.font-title {
    @extend %font-title;

    &-bold {
        @extend %font-title-bold;
    }

    &-italic {
        @extend %font-title-italic;
    }
}


//
// Text Styling
//--------------------------------------

.text-center, 
%text-center  {
    text-align: center;
}

.text-uppercase, 
%text-uppercase  {
    text-transform: uppercase;
}

.text-italic, 
%text-italic  {
    font-style: italic;
}

.text-underline, 
%text-underline  {
    text-decoration: underline;
}

.text-no-underline, 
%text-no-underline {
    text-decoration: none;
}

.text-white-shadow, 
%text-white-shadow {
    text-shadow: 0 2px 4px rgba($color-black, 0.5);
    color: $color-white;
}

.text-no-margin, 
%text-no-margin {
    margin-bottom: 0;
}

.text-micro, 
%text-micro {
    font-size: rem(10px);
}

.text-small, 
%text-small {
    font-size: rem(12px);
}

.text-large, 
%text-large {
    font-size: rem(18px);
}

.text-thin {
    font-weight: $font-thin;
}

.text-normal {
    font-weight: $font-normal;
}

.text-semibold {
    font-weight: $font-semibold;
}

.text-bold {
    font-weight: $font-bold;
}

.text-heavy {
    font-weight: $font-black;
}

//
// Text Colors
//--------------------------------------

.text-black, 
%text-black  {
    color: $color-black;
}

.text-white, 
%text-white  {
    color: $color-white;
}

.text-offWhite, 
%text-offWhite  {
    color: $color-offwhite;
}

.text-grey-light, 
%text-grey-lighter {
    color: $color-grey-light;
}

.text-grey-light, 
%text-grey-light {
    color: $color-grey-light;
}

.text-grey, 
%text-grey {
    color: $color-grey;
}

.text-grey-dark, 
%text-grey-dark {
    color: $color-grey-dark;
}

.text-grey-dark, 
%text-grey-darker {
    color: $color-grey-dark;
}

//
// Font Inherit from Parent
//--------------------------------------

.font-inherit, 
%font-inherit {
    line-height: inherit;
    letter-spacing: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
}


//
// Element Styling
//--------------------------------------

html {
    font-size: 100%;
}

body,
caption,
th,
td,
input,
textarea,
select,
option,
legend,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size-adjust: 0.5;
}

body {
    font-size: 1rem;
    line-height: 1.25;
}

hr {
    border: 1px solid $color-grey;

    &.line-light {
        border: 1px solid $color-grey-light;
    }
}

h1, 
%h1 {
    font-size: 2rem;
    line-height: 1.25;
    margin-top: 2rem;
}

h2,
%h2 {
    font-size: 1.625rem;
    line-height: 1.15384615;
    margin-top: 2rem;
}

h3,
%h3 {
    font-size: 1.375rem;
    line-height: 1.13636364;
    margin-top: 2rem;
}

h4,
%h4 {
    font-size: 1.125rem;
    line-height: 1.11111111;
    margin-top: 2rem;
}

h5,
%h5 {
    font-size: 1rem;
    line-height: 1.25;
    margin-top: 2rem;
}

h6,
%h6 {
    font-size: 0.75rem;
    line-height: 1.25;
    margin-top: 2rem;
}

blockquote {
    font-size: 1.25rem;
    line-height: 1.25;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.page-title-wrapper {
    padding: 0;

    .page-title {
        @extend %h1;
        margin-bottom: 3rem;
        margin-top: 0;
        padding: 0;
    }
}

// Large Tablet
// -----------------------------------------------------------------------------
// Elem | Font | Line
// -----------------------------------------------------------------------------
// Body | 16px | 22px
// h1   | 40px | 45px
// h2   | 32px | 40px
// h3   | 24px | 30px
// h4   | 18px | 22px
// h5   | 16px | 22px
// h6   | 14px | 19.25px
// BQ   | 24px | 33px

@media (min-width: 700px) {
    body {
        line-height: 1.375rem;
    }

    h1,
    %h1 {
        font-size: 2.5rem;
        line-height: 1.125;
    }

    h2,
    %h2 {
        font-size: 2rem;
        line-height: 1.125;
    }

    h3,
    %h3 {
        font-size: 1.5rem;
        line-height: 1.25;
    }

    h4,
    %h4 {
        font-size: 1.125rem;
        line-height: 1.22222222;
    }

    h5,
    %h5 {
        font-size: 1rem;
        line-height: 1.375;
    }

    h6,
    %h6 {
        font-size: 0.75rem;
        line-height: 1.203125;
    }

    blockquote {
        font-size: 1.5rem;
        line-height: 1.375;
    }
}

// Large Desktop
// -----------------------------------------------------------------------------
// Elem | Font | Line
// -----------------------------------------------------------------------------
// h1   | 48px | 50px
// h2   | 36px | 45px
// h3   | 28px | 35px

@media (min-width: 900px) {
    h1, 
    %h1 {
        font-size: 3rem;
        line-height: 1.05;
    }

    h2,
    %h2 {
        font-size: 2.25rem;
        line-height: 1.125;
    }

    h3,
    %h3 {
        font-size: 1.75rem;
        line-height: 1.25;
    }
}


//
// Hyperlinks
//--------------------------------------

a {
    text-decoration: underline;
    background: transparent;
    color: $color-primary;

    &:hover, 
    &:visited {
        color: $color-primary-dark;
    }

    &.link-alt, 
    &.link-alternate {
        color: $color-secondary;
        text-decoration: none;

        &:hover {
            color: $color-secondary-dark;
        }
    }
}

//
// Fix List Styles w/ .items
//--------------------------------------

.items {
    @include lib-list-reset-styles();
}


//
// Icomoon
//--------------------------------------
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/icomoon.eot');
    src: url('../fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.woff') format('woff'),
    url('../fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon/icomoon.svg#icomoon') format('svg');
    font-weight: 400;
    font-style: normal;
}
