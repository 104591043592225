// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$checkout-modal-popup__width: 800px;

//
//  Common
//  _____________________________________________


.checkout-index-index {
    .modal-popup {
        .field-tooltip {
            .field-tooltip-content {
                @extend .abs-checkout-tooltip-content-position-top;
            }
        }

        .fieldset {
            .field {
                .label {
                    font-weight: $font-weight__regular;
                }
            }
        }

        .modal-footer {
            .action-hide-popup {
                @extend .abs-action-button-as-link;
                margin-top: 8px;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-index-index {
        .modal-popup {
            .modal-footer {
                .action-save-address {
                    width: 100%;
                }

                .action-hide-popup {
                    margin-top: $indent__base;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-index-index {
        .modal-popup {
            .form-shipping-address {
                //max-width: $checkout-shipping-address__max-width;

                #shipping-new-address-form {
                    padding-bottom: 55px;
                }
            }

            .modal-footer {
                .action-save-address {
                    float: right;
                    margin: 0 0 0 $indent__base;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .checkout-index-index {
        .modal-popup {
            .modal-inner-wrap {
                margin-left: -($checkout-modal-popup__width / 2);
                width: $checkout-modal-popup__width;
                left: 50%;
            }
        }
    }
}
