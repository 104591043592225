//
// Layout Variables
// --------------------------------------

$column-spacing: 10px;

$layouts: ( // <columns>, <bp:small>, <bp:medium>
    (2, 100%, 100%, 50%),
    (3, 100%, 100%, 33%),
    (4, 100%, 50%, 25%),
    (5, 100%, 50%, 20%)
);


//
// Parent Setup
// --------------------------------------

%columns-flex {
    display: flex;
    flex-wrap: wrap;

    &--reverse {
        flex-wrap: wrap-reverse;
    }

    &--center {
        justify-content: center;
    }

    &--space-bewtween {
        justify-content: space-between;
    }
}


//
// Column Mixin
// --------------------------------------

@mixin column-widths($width-mobile, $width-tablet, $width-desktop) {
    width: $width-mobile;

    @include bp(min-width, $bp-small) {
        width: $width-tablet;
    }

    @include bp(min-width, $bp-medium) {
        width: $width-desktop;
        padding: {
            right: $column-spacing;
            left: $column-spacing;
        }
    }
}


//
// Build Layout
// --------------------------------------

@each $layout in $layouts {
    $count: nth($layout, 1);
    $mobile: nth($layout, 2);
    $tablet: nth($layout, 3);
    $desktop: nth($layout, 4);

    // Build Layout Wrapper
    .col#{$count}__layout {
        @extend %columns-flex;

        // Build Columns
        .col {
            @include column-widths($mobile, $tablet, $desktop);

            @for $i from 1 through $count {
                &:nth-of-type(#{$count}n+1),
                &:first-of-type {
                    padding-left: 0;
                }

                &:nth-of-type(#{$count}n+#{$count}),
                &:last-of-type {
                    padding-right: 0;
                }

                &--#{$i} {
                    @include column-widths($mobile, $tablet, $desktop);
                }
            }
        }
    }
}