.product-info-main {

    .field {

        label {
            font-weight: 700;
        }

        .note {
            font-style: normal;
            font-size: 0.8rem;
            margin: 0;
            font-weight: bold;
        }
    }
}

.custom-options__container {
    display: block;
    margin-bottom: 15px;

    .field {
        //width: 50%;
        max-width: 380px;
        width: 100%;
    }
}
#product-options-wrapper {
    select,
    input {
        max-width: 325px;
    }
    .field .control {
        position: relative;
    }
    .tooltip {
        position: absolute;
        top: 0;
        right: 0;
    }
    .fieldset > .field,
    .fieldset > .fields > .field {
        clear: left;
    }
}