/* Reset - A few useful declerations to stop other peoples CSS interfering with our own */

.sxy-zoom-container .sxy-zoom-viewport img { max-width: none !important; border-radius: 0 !important; }

/* Global */

.sxy-zoom-container { /* -moz-box-shadow: 0 1px 6px #aaa; -webkit-box-shadow: 0 1px 6px #aaa; box-shadow:  0 1px 6px #aaa; */}
.sxy-zoom-container .sxy-zoom-viewport { z-index: 1000; }

.sxy-zoom-container img { border: 0; }
.sxy-zoom-container div { margin: 0; padding: 0; border: 0; }

.sxy-zoom-container .sxy-zoom-dragpad, .sxy-zoom-container .sxy-overlay, .sxy-zoom-container .inner-thumb, .sxy-zoom-container .sxy-zoom-bg { width: 100%; height: 100%; }

.sxy-zoom-bg { left: 0; top: 0; position: absolute; }

.sxy-zoom-container .sxy-controls { height: 100%; }
.sxy-zoom-container .overlay { height: 100%; }
.sxy-zoom-container .sxy-slider { height: 100%; }

.sxy-zoom-container .sxy-zoom-dragpad { position: absolute !important; left: 0; top: 0; }

/* Controls */

.sxy-zoom-container .sxy-controls
{
    box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box;
    width: 26px;
    left: -26px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    filter:inherit;
}

.sxy-zoom-container .sxy-controls .overlay
{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 26px;
    border-top-left-radius: 3px; -webkit-border-top-left-radius: 3px; -moz-border-radius-topleft: 3px;
    border-bottom-left-radius: 3px; -webkit-border-bottom-left-radius: 3px; -moz-border-radius-bottomleft: 3px;
    background:#fff;
    filter: inherit;
}

.sxy-zoom-container .in {    filter:inherit;  left: 2px; width: 22px; height: 23px; background:  url(controls.png) 0px -15px; position: relative; display: block; top: 3px;}
.sxy-zoom-container .out { filter:inherit; width: 22px; height: 23px; background:  url(controls.png) 0px -38px; position: relative; left: 2px; display: block; }
.sxy-zoom-container .sxy-slider { filter:inherit; background:  url(controls.png) -25px 0px repeat-y; position: relative;}
.sxy-zoom-container .sxy-handle { filter:inherit; left: 2px; width: 20px; height: 16px; background:  url(controls.png) 0px 0px no-repeat; top: 20px; position: absolute;font-size: 0; }

/* Mode :: Common */

.sxy-zoom-container { position: relative; }

.sxy-zoom-container .sxy-zoom-viewfinder { position: absolute; border: 1px solid #d9cfba; border:1px solid rgba(255,255,255,0.4);  box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; }

.sxy-zoom-container .inner-thumb { position: absolute; top: 0; left: 0; }

.sxy-zoom-container .sxy-zoom-dragpad { position: relative; }
.sxy-zoom-container .sxy-zoom-dragpad .sxy-overlay { position: absolute; top: 0px; left: 0px; }

.sxy-zoom-container .sxy-zoom-viewport { position: absolute; overflow: hidden; top:0; }
.sxy-zoom-container .sxy-zoom-viewport img { position: absolute; }

.sxy-zoom-container.sxy-zoom-loading .sxy-loading { background: #fff; padding: 5px; position: absolute; top:0; left:0; margin: 5px;  border-radius: 6px; -webkit-border-radius: 6px; -moz-border-radius: 6px; box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68); -webkit-box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68); -moz-box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68); opacity: 0.35;  filter: alpha(opacity = 35);}
.sxy-zoom-container.sxy-zoom-loading .sxy-loading  span { background: url(loading.gif) no-repeat; width: 25px; height: 25px; display: block;}


/* Mode :: Dock */

.sxy-zoom-mode-dock  .sxy-zoom-viewport { box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; border: 1px solid #000; position: absolute; overflow: hidden; top:0; box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68); -webkit-box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68);  -moz-box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68);}
.sxy-zoom-mode-dock  .sxy-zoom-dragpad .sxy-overlay { background: #000000; opacity: 0.0; filter: alpha(opacity = 0); }

/* Mode :: Window */

.sxy-zoom-mode-window .sxy-zoom-viewfinder { z-index: 1001; }

/* Mode :: Lens */

.sxy-zoom-mode-lens  .sxy-zoom-viewport { border: 1px solid #000; position: absolute; overflow: hidden; top:0; box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68); -webkit-box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68); -moz-box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.68); }

/* Mode :: Slippy */

.sxy-zoom-mode-slippy  .sxy-zoom-dragpad { cursor: pointer; }
.sxy-zoom-mode-slippy  .sxy-zoom-dragpad.down { cursor: move; }