// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Advanced Search
//  _____________________________________________

// This part of code fixes the gap between page title and error message
// In Luma on Mobile screens we have top padding on '.column' to give room for absolutely positioned toolbar controls.
// If Advanced Search returns no results, controls do not appear, and visual gap appears.
// We compensate it with negative top margin applied to '.message.error' on Mobile screens.

//
// Common
// _____________________________________________

.catalogsearch-advanced-result {
    .message {
        &.error {
            margin-top: -60px;

            @include bp(min-width, $screen__s) {
                margin-top: 0;
            }
        }
    }
}
