//
// Variables
// ---------------------------------------------

$product-header-media:  35%;
$product-header-info:   100% - $product-header-media;


//
// Layout
// ---------------------------------------------

.product__header {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 60px;
    
    @include clearfix;
}

.product.media {
    margin: 0 auto;
    @include bp(min-width, $bp-medium) {
        width: $product-header-media;
        padding: 25px 0 0;
    }
}

.product-info-main {
    margin: 0 auto;
    @include bp(min-width, $bp-medium) {
        width: $product-header-info;
        float: right;
        padding: 25px 0 0 44px;
    }

    .product-info-price {
        margin-bottom: 10px;
    }

    .price-excluding-tax {
        display: none;
    }

    label {
        margin-bottom: 10px;
    }

    .product-options-bottom {

        .fieldset {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .field {
                min-width: 100px;
            }
        }
    }

    .box-tocart {
        .label {
            margin-right: 10px;
        }

        .fieldset {
            @include bp(min-width, $bp-medium) {
                display: flex;
                justify-content: space-between;
            }
        }

        select {
            max-width: 50px;
        }

        .action {
            margin: 10px 0 0;
        }
    }

    .tocart {
        @include bp(max-width, $bp-small) {
            width: 100%;
        }
    }
}
.product-info-stock-sku {
    display: none;
}
.catalog-product-view {
    //background: url(../../../images/pdp.png) center -32px/1200px auto no-repeat;

    .page-title {
        margin: 0 0 10px;
        font-size: rem(15px);

        span {
            @extend %h1;
            @extend %font-base;
            text-align: center;
            font-size: rem(15px);
            font-weight: $font-weight__bold;
        }
    }

}
.discount-msg {
    margin: 20px 0 0 10px;
}
.rules-msgs {
    margin: 15px 0;

    p {
        font-weight: $font-weight__bold;
    }
}
.totals.sub.incl,
.include-only .price-excluding-tax,
.subtotal .price-excluding-tax
//.exclude-only .price-including-tax
{
    display: none;
}