//
// Variables
// ---------------------------------------------


//
// Login Page
// ---------------------------------------------

.customer-account-login {

    .page-main {
        @include section-width($site-width-small);
    }

    .login-container {
        @include clearfix;
    }

    // Layout
    .block-customer-login,
    .block-new-customer {
        padding: 0 percentage(20px/$site-width);
        display: block;
        float: left;
        width: 50%;
        @include bp(max-width, $bp-medium) {
            width: 100%;
        }
    }

    .block-customer-login {
        padding-left: 0;
    }

    .block-new-customer {
        padding-right: 0;
    }
}