.print-only {
    display: none;
}
.sales-order-printinvoice,
.sales-order-printshipment,
.sales-order-print {
    .print-only {
        display: block;
    }
    .order-status,
    .nav-toggle {
        display: none;
    }
    #my-invoice-table,
    #my-orders-table {
        display: table;
        tbody,
        tfoot,
        thead {
            @include bp(min-width, $bp-medium) {
                display: table-row-group;
            }
        }
        tbody {
            td {
                padding-bottom: 20px;
                &:before {
                    @include bp(min-width, $bp-medium) {
                        display: none;
                    }
                }
            }
        }
        tfoot {
            tr,
            th {
                text-align: right;
            }
        }
        tr {
            @include bp(min-width, $bp-medium) {
                display: table-row;
            }
        }
        th,
        td {
            @include bp(min-width, $bp-medium) {
                display: table-cell;
            }
        }
        tfoot td {
            &:before {
                display: none;
            }
        }
    }
    caption {
        padding: 5px;
        text-align: left;
    }
    .block-order-details-view {
        .box {
            width: 25%;
            float: left;
        }
        .block-content {
            overflow: hidden;
        }
    }
    #maincontent {
        position: relative;
    }
}
.bricard-invoice-info {
    background: $color-primary-blue-light;
    border: 1px solid $color-primary-blue-dark;
    color: $color-black;
    padding: 20px;
    max-width: 350px;
    width: 100%;
    margin: 0 0 0 auto;
}
.invoice-logo {
    position: absolute;
    right: 0;
    top: 0;
    img {
        max-width: 130px;
    }
}