// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________



.block-banners,
.block-banners-inline {
    // should extend only the class below like less extend('.className')
    // not others selectors like "div.className" etc.
    // look out: sass otherwise than less extend element with its children;
    @extend .abs-margin-for-blocks-and-widgets;

    .banner-items {
        @extend .abs-reset-list;
    }

    .banner-item {
        display: block;
    }

    .banner-item-content {

        img {
            display: block;
            margin: 0 auto;
        }
    }
}
