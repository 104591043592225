// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Sections variables
//  _____________________________________________

//
//  Tabs
//  ---------------------------------------------

//  Tabs controls

$tab-control__font-family: inherit;
$tab-control__font-style: inherit;
$tab-control__height: $indent__base;

$tab-control__padding-top: $indent__xs;
$tab-control__padding-bottom: $indent__xs;
$tab-control__padding-right: $indent__base;
$tab-control__padding-left: $tab-control__padding-right;
$tab-control__border-color: $border-color__base;
$tab-control__border-width: $border-width__base;

//  Current tab
$tab-control__background-color: $panel__background-color;
$tab-control__color: $text__color__muted;
$tab-control__hover__color: $tab-control__color;
$tab-control__hover__background-color: lighten($tab-control__background-color, 5%);
$tab-control__text-decoration: none;
$tab-control__hover__text-decoration: $tab-control__text-decoration;
$tab-control__active__background-color: lighten($tab-control__background-color, 15%);

$tab-content__border: $tab-control__border-width solid $tab-control__border-color;
$tab-content__margin-top: $tab-control__height + $tab-control__border-width + $tab-control__padding-top + $tab-control__padding-bottom;

$tab-content__padding-top: $indent__base;
$tab-content__padding-right: $indent__base;
$tab-content__padding-bottom: $indent__base;
$tab-content__padding-left: $indent__base;

$accordion-control__padding-top: $tab-control__padding-top;
$accordion-control__padding-right: $indent__base;
$accordion-control__padding-bottom: $tab-control__padding-bottom;
$accordion-control__padding-left: $indent__base;
