// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//  Variables
// _____________________________________________
$minicart__border-color      : $color-gray80;
$minicart__padding-horizontal: $indent__base;
$minicart-qty__height        : 24px;
//
//  Common
//  _____________________________________________
//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;
        .count {
            font-weight: $font-weight__bold;
        }
    }
    .subtotal {
        margin: 0 $indent__s;
        text-align: right;
        .label {
            @extend .abs-colon;
        }
    }
    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }
    .subtitle {
        display: none;
        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .block-content {
        & > .actions {
            margin-top: 15px;
            & > .secondary {
                text-align: center;
            }
            & > .primary {
                margin: 0 $indent__s 15px;
                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        width: 100%;
                    }
                }
            }
            .paypal-logo {
                margin-top: 15px;
                text-align: center;
            }
        }
    }
    .block-banners,
    .block-category-link,
    .block-cms-link,
    .block-product-link {
        margin: 15px 0 0;
        text-align: center;
    }
}
.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector                    : ".action.showcart",
        $_options-selector                   : ".block-minicart",
        $_dropdown-toggle-icon-content       : $icon-cart,
        $_dropdown-toggle-active-icon-content: $icon-cart,
        $_dropdown-list-item-padding         : inherit,
        $_dropdown-list-item-hover           : inherit,
        $_icon-font-position                 : before,
        $_icon-font-size                     : 22px,
        $_icon-font-line-height              : 28px,
        $_icon-font-color                    : $minicart-icons-color,
        $_icon-font-color-hover              : $minicart-icons-color-hover,
        $_icon-font-color-active             : $minicart-icons-color
    );
    float: right;
    .block-minicart {
        padding: 25px $minicart__padding-horizontal;
        right: 0;
        width: 320px;
        .block-title {
            display: none;
        }
        &:after {
            left: auto;
            right: 25px;
        }
        &:before {
            left: auto;
            right: 26px;
        }
        li {
            padding: 15px 0;
            &:last-child {
                padding-bottom: 0;
            }
            &:first-child {
                padding-top: 0;
            }
        }
    }
    .product {
        .actions {
            float: right;
            margin: 10px 0 0;
            & > .primary,
            & > .secondary {
                display: inline;
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }
    .action {
        &.close {
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            color: $minicart-icons-color-hover;
            @include lib-button-reset();
            @include lib-button-icon(
                $icon-remove,
                $_icon-font-color      : $minicart-icons-color,
                $_icon-font-size       : 34px,
                $_icon-font-line-height: 16px,
                $_icon-font-text-hide  : true
            );
        }
        &.showcart {
            white-space: nowrap;

            .counter.qty {
                background: $active__color;
                color: $page__background-color;
                height: 20px;
                width: 20px;
                line-height: 1.6;
                border-radius: 50%;
                font-size: 0.8rem;
                display: inline-block;
                overflow: hidden;
                text-align: center;
                white-space: normal;
                position: absolute;
                top: -10px;
                right: 0;

                @include bp(min-width, $bp-medium) {
                    top: -5px;
                }
                
                &.empty {
                    display: none;
                }
                .loader {
                    > img {
                        max-width: $minicart-qty__height;
                    }
                }
            }
            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }
    .minicart-widgets {
        margin-top: 15px;
    }
}
.minicart-items-wrapper {
    border: 1px solid $minicart__border-color;
    margin: 0 (0 - $minicart__padding-horizontal);
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 15px;
}
.minicart-items {
    @include lib-list-reset-styles();
    .product-item {
        padding: $indent__base 0;
        &:not(:first-child) {
            border-top: 1px solid $minicart__border-color;
        }
        &:first-child {
            padding-top: 0;
        }
        & > .product {
            @extend .abs-add-clearfix;
        }
    }
    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }
    .price-minicart {
        margin-bottom: $indent__xs;
    }
    .price-excluding-tax {
        display: none;
    }
    .product-item-name {
        font-weight: $font-weight__regular;
        margin: 0 0 $indent__s;
        a {
            color: $link__color;
        }
    }
    .product-item-details {
        padding-left: 130px;
        .price {
            font-weight: $font-weight__bold;
        }
        .price-excluding-tax,
        .price-including-tax {
            margin: $indent__xs 0;
        }
        .weee[data-label] {
            font-size: 11px;
            .label {
                @extend .abs-no-display;
            }
        }
        .details-qty {
            margin-top: $indent__s;
        }
    }
    .product {
        & > .product-image-container,
        & > .product-item-photo {
            float: left;
        }
        .toggle {
            // TO DO - The selector ".abs-toggling-title" was not found.
            // @extend .abs-toggling-title !optional;
            &:after {
                color: $color-gray56;
                margin: 0 0 0 $indent__xs;
                position: static;
            }
            border: 0;
            padding: 0 $indent__xl $indent__xs 0;
        }
        .active {
            & > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }
        &.pricing {
            margin-top: 3px;
        }
        &.options {
            .tooltip.toggle {
                @include lib-icon-font(
                    $icon-down,
                    $_icon-font-size       : 12px,
                    $_icon-font-line-height: 12px,
                    $_icon-font-text-hide  : true,
                    $_icon-font-margin     : -3px 0 0 7px,
                    $_icon-font-position   : after
                );
            }
            .details {
                display: none;
            }
        }
    }
    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }
    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 60px;
    }
    .update-cart-item {
        font-size: 11px;
        vertical-align: top;
        margin: 10px 0 0;
        float: right;
    }
    .subtitle {
        display: none;
    }
    .action {
        &.delete {
            @include lib-icon-font(
                $_icon-font-content: $icon-trash,
                $_icon-font-size        : 34px,
                $_icon-font-line-height : 20px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $minicart-icons-color,
                $_icon-font-color-hover : $primary__color,
                $_icon-font-color-active: $minicart-icons-color
            );
        }
    }
}
