// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//    Common
//--------------------------------------

.tooltip.wrapper {
    @include lib-tooltip(
        $_tooltip-position        : bottom,
        $_tooltip-selector-content: ".tooltip.content",
        $_tooltip-selector-toggle : ".tooltip.toggle"
    );

    .tooltip.content {
        dl {
            margin-bottom: 0;
        }

        dd {
            white-space: normal;
        }

        .subtitle {
            display: inline-block;
            margin-bottom: 15px;
            font-size: 16px;
            font-weight: 500;
        }

        .label {
            margin-top: $indent__s;
            &:first-child {
                margin-top: 0;
            }
        }

        .values {
            margin: 0;
        }
    }
}

.ui-tooltip {
    position: absolute;
    z-index: 9999;
}
