// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________

//  PayPal checkout button
.paypal {
    display: inline-block;
    vertical-align: top;
    .block-minicart &,
    .cart-summary & {
        display: block;
    }

    img {
        display: block;
        margin: $indent__xs auto 0;
    }

    .opc & {
        vertical-align: middle;
    }

    &.acceptance {
        display: block;
        margin: 0 0 $indent__base;
    }

    .map-form-addtocart & {
        display: block;
        margin: $indent__s auto $indent__xs;
    }

    .cart-summary & {
        + .paypal {
            margin-top: $indent__m;
        }
    }
}

.paypal-button-widget {
    .paypal-button {
        @extend .abs-action-button-as-link;
    }
}
