//
// Center Block
//--------------------------------------

%block-center {
    display: block;
    margin: {
        right: auto;
        left: auto;
    }
}


//
// Disable Scroll
//--------------------------------------

%no-scroll {
    overflow: hidden;
    position: fixed;
    height: 100%;
    margin: 0;
}


//
// Visibility Classes
//--------------------------------------

.hide {
    display: none;
}

.show {
    display: block;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}


//
// Visibility By Breakpoint
//--------------------------------------

$breakpoints: (
    // <name>, <variable>
    ("xsmall", $bp-xsmall),
    ("small", $bp-small),
    ("medium", $bp-medium),
    ("large", $bp-large),
    ("xlarge", $bp-xlarge)
);

@each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $bp: nth($breakpoint, 2);

    // Using max-width to avoid using a "display: block"
    .hide-#{$name}-down {
        @include bp(max-width, $bp) {
            display: none;
        }
    }

    .hide-#{$name}-up {
        @include bp(min-width, $bp) {
            display: none;
        }
    }
}

// Hide for Desktop
.hide-for-desktop {
    @include bp(min-width, $bp-medium) {
        display: none;
    }
}

// Show for Desktop
.show-for-desktop {
    @include bp(max-width, $bp-medium) {
        display: none;
    }
}




//
// Background Colors
//--------------------------------------


.bg__black, %bg__black {
    background-color: $color-black;
}

.bg__white, %bg__white {
    background-color: $color-white;
}

.bg__grey, %bg__grey {
    background-color: $color-grey;
}