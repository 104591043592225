// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//  Variables
//  _____________________________________________
$autocomplete__background-color: $color-white;
$autocomplete__border: 1px solid $form-element-input__border-color;
$autocomplete-item__border: 1px solid $color-gray90;
$autocomplete-item__hover__color: $color-gray91;
$autocomplete-item-amount__color: $color-gray60;
//
//  Common
//  _____________________________________________
.block-search {
    margin: -20px 0;

    @include bp(max-width, $bp-medium) {
        background: $color-primary;
        margin: 0;
        border-top: 2px solid $color-primary;
        width: 100%;
    }

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font( $_icon-font-content : $icon-search, $_icon-font-size : 22px, $_icon-font-line-height : 28px, $_icon-font-margin : 0 $indent__s 0 0, $_icon-font-color : $minicart-icons-color, $_icon-font-color-hover : $minicart-icons-color-hover, $_icon-font-color-active: $minicart-icons-color-hover, $_icon-font-text-hide : true );
        display: inline-block;
        display: inline-block;

        &.active {
            & + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        width: 100%;
        clear: both;
        display: inline-block;
    }

    .nested {
        display: none;
    }

    .field.search {
        position: relative;

        @include bp(max-width, $bp-medium) {
            label {
                position: absolute;
                top: 0;
                left: 0;
                width: 37px;
                height: 37px;
                background: $color-grey;

                &::before {
                    width: 37px;
                    height: 37px;
                    line-height: 1.3;
                    font-size: 1.8rem;
                    color: $color-grey-light;
                }
            }
        }
    }

    input {
        padding-left: 50px;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        &:not(:empty) {
            background: $autocomplete__background-color;
            border: $autocomplete__border;
            border-top: 0;
        }

        li {
            border-top: $autocomplete-item__border;
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:first-child {
                border-top: none;
            }

            &.selected,
            &:hover {
                background: $autocomplete-item__hover__color;
            }

            .amount {
                color: $autocomplete-item-amount__color;
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    // TO DO - The selector ".abs-forms-general-desktop" was not found.
    // @extend .abs-forms-general-desktop !optional;
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }

            &.date {
                @extend .abs-field-date;
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search-terms {
    @extend .abs-reset-list;
    line-height: 2;

    & > li {
        display: inline-block;
        margin-right: $indent__s;
    }
}

.search.found {
    margin-bottom: $indent__s;
}
//
//  Mobile
//  _____________________________________________
@include max-screen($screen__s) {
    .block-search {
        margin-top: $indent__s;
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .block-search {
        float: right;
        padding-left: 15px;
        position: relative;
        width: auto;
        z-index: 4;

        .control {
            border-top: 0;
            margin: 0;
            padding: 0;
            width: auto;
            min-width: 220px;
        }

        input {
            @include lib-input-placeholder() {
                color: $form-element-input-placeholder__color;
            }
            margin: 0;
            padding-right: 35px;
            padding-left: 10px;
            position: static;
        }

        .action.search {
            display: inline-block;
            @include lib-button-icon($_icon-font-content : $icon-search, $_icon-font-size : 28px, $_icon-font-text-hide : true, $_icon-font-color : $color-grey-light, $_icon-font-color-hover: $color-grey-light, $_icon-font-line-height: 38px);
            @include lib-button-reset();
            background-color: $color-grey-dark;
            position: absolute;
            right: 5px;
            top: 0;
            z-index: 1;
        }

        .label {

            &::before {
                content: '';
            }

            span {
                width: auto;
                position: relative;
                margin: 0;
                height: auto;
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}
