//
// CMS Block - Section Container
// ---------------------------------------------

.cms__block {
    @include section-width();
    padding: {
        bottom: $cms-padding;
        top: $cms-padding;
    }

    &--smallest {
        @include section-width($site-width-smallest)
    }

    &--smaller {
        @include section-width($site-width-smaller)
    }

    &--small {
        @include section-width($site-width-small)
    }

    &--large {
        @include section-width($site-width-large)
    }

    &--larger {
        @include section-width($site-width-larger)
    }

    &--full {
        @include section-width($site-width-full, 0, 0);
    }

    &--no-padding {
        padding: {
            bottom: 0;
            top: 0;
        }
    }

    &--no-padding-top {
        padding-top: 0;
    }

    &--no-padding-bottom {
        padding-top: 0;
    }
}


//
// CMS Background Colors
// ---------------------------------------------

.cms__bg, %cms__bg {

    // Image
    &--image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    // Colors
    &--black {
        background-color: $color-black;
    }

    &--white {
        background-color: $color-white;
    }

    &--grey, &--gray {
        background-color: $color-grey;
    }
}