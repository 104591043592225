// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//    Common
//--------------------------------------

.block-cms-hierarchy-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-cms-hierarchy-link-inline {
    &.widget {
        margin: 0;
    }
}
