// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________



.table-wrapper.grouped {
    width: auto;
}

.table.grouped {
    .row-tier-price {
        & > td {
            border-top: 0;
        }
    }

    .col.item {
        width: 90%;
    }

    .col.qty {
        width: 10%;

        .control.qty {
            .qty {
                @extend .abs-input-qty;
            }
        }
    }

    .product-item-name {
        font-weight: $font-weight__regular;
        margin-bottom: $indent__s;
        display: inline-block;
    }

    .price-box {
        p {
            margin: 0;
        }

        .price {
            font-weight: $font-weight__bold;
        }

        .old-price {
            .price {
                font-weight: normal;
            }
        }
    }

    .stock.unavailable {
        margin: 0;
        white-space: nowrap;
        text-transform: none;
        font-weight: normal;
    }
}

.page-product-grouped .product-info-price {
    float: right;
    display: block;
    width: auto;
    border: 0;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .table.grouped {
        & > thead {
            & > tr {
                & > th {
                    border-bottom: $table__border-width $table__border-style $table__border-color;
                    display: table-cell;
                }
            }
        }

        & > tbody {
            border-bottom: $table__border-width $table__border-style $table__border-color;

            & > tr {
                & > td {
                    // TO DO - The selector ".abs-col-no-prefix" was not found.
                    // @extend .abs-col-no-prefix !optional;
                    padding: $table-cell__padding-vertical $table-cell__padding-horizontal;
                    border: 0;
                    display: table-cell;
                }
            }
        }

        .table-wrapper & {
            &:not(.totals):not(.cart):not(.table-comparison) {
                & > tbody {
                    & > tr {
                        & > td {
                            &:last-child {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .table-wrapper.grouped {
        margin-left: -$layout__width-xs-indent;
        margin-right: -$layout__width-xs-indent;
    }
}
