//
// Base Styling
// --------------------------------------

@import "modules/base";


//
// Individual Option
// --------------------------------------

.swatch-option {

    &.text {
        line-height: 12px;
    }
}

//
// Options Wrapper
// --------------------------------------

.swatch-attribute-options {
    padding: 0 1px; // Options on the edge of container being cut off
    margin: 5px 0;
}

