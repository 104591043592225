// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________
.panel.header {
    .links,
    .switcher {
        display: none;
    }

    .links {
        float: right;
    }

    .switcher-language {
        float: left;
    }
}

.nav-sections {
    background: $navigation__background;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size       : 28px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    z-index: 14;
    margin-right: 0;

    &::before {
        margin: 0;
    }
}
.navigation {
    .switcher-language .label.switcher-label,
    .switcher-language {
        display: none;
    }
    .switcher-dropdown {
        width: 100%;
    }

    .switcher-trigger {
        @include lib-icon-font($_icon-font-content : $icon-down,
        $_icon-font-size : 2.4rem,
        $_icon-font-color: $color-white,
        $_icon-font-position: after,
        $_icon-font-display : block,
        $_icon-font-margin: 0,
        $_icon-font-line-height: 0.6);
        padding: 20px;
        color: $color-white;
        font-weight: $font-bold;

        &:hover {
            cursor: pointer;

            &::after {
                color: $color-white;
            }
        }

        &::after {
            margin-left: -8px;
        }
    }
}
//
//  Mobile
//  _____________________________________________

$active-nav-indent: 54px;
@include max-screen($screen__m) {
    .navigation {
        padding: 0;
        .switcher-dropdown[aria-hidden=true] {
            display: block;
        }
        .switcher-language {
            display: block;
        }

        .parent {
            .level-top {
                position: relative;
                &.opened,
                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content : $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }
    }

    .nav-sections {
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }

            &-trigger {
                strong {
                    display: inline-block;
                    position: relative;
                    @include lib-icon-font(
                        $_icon-font-content : $icon-down,
                        $_icon-font-size    : 42px,
                        $_icon-font-position: after,
                        $_icon-font-display : block
                    );

                    &:after {
                        position: absolute;
                        right: -40px;
                        top: -18px;
                    }
                }

                &.active strong {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                    );
                }
            }

            &-dropdown {
                margin: 0;
                padding: 0;
                list-style: none none;
                background: #fff;
                border: 1px solid #bbb;
                min-width: 160px;
                z-index: 100;
                box-sizing: border-box;
                display: none;
                position: absolute;
                box-shadow: 0 3px 3px rgba(0, 0, 0, .15);
            }
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: $font-size__base;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                & > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                color: $navigation-level0-item__color;
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            box-shadow: $_shadow;
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, 0.01);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections {
        &-items {
            @include lib-clearfix();
            position: relative;
            z-index: 1;
        }

        &-item-title {
            background: darken($navigation__background, 5%);
            border: solid darken($navigation__background, 10%);
            float: left;
            height: 71px;
            padding-top: 24px;
            text-align: center;
            width: 33.33%;

            &.active {
                background: transparent;
                border-bottom: 0;
            }

            .nav-sections-item-switch {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-item-content {
            @include lib-clearfix();
            float: right;
            margin-left: -100%;
            margin-top: 71px;
            width: 100%;

            &.active {
                display: block;
            }

            padding: $indent__m 0 0;
        }
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        flex-grow: 0;
        flex-basis: auto;

        &-item-title {
            display: none;
        }

        &-item-content {
            display: block;
        }

        &-item-content > * {
            display: none;
        }

        &-item-content > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
