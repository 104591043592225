//
// Base Slick Slider Setup // Overwrites
// --------------------------------------

.slick-slider {

    // Arrows
    .slick-arrow {
        background-color: transparent;
        z-index: 1;

        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 15px;
        }

        &.slick-prev {
            &::before {
                background-image: url('#{$baseDir}images/icon-arrow-left.svg');
            }
        }

        &.slick-next {
            &::before {
                background-image: url('#{$baseDir}images/icon-arrow-right.svg');
            }
        }
    }

    // Dots
    .slick-dots {
        $margin:    5px;
        $dot:       9px;
        margin: 0 0 30px;

        li {
            margin: 0;

            &.slick-active {
                button {
                    background-color: $color-base;
                }
            }

            button {
                background-color: $color-grey-dark;
                padding: $dot/2;
                margin: $margin/2;
            }
        }
    }
}