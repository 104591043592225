// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________

.pages {
    @include lib-pager();
    .action {

        &.previous {
            margin-right: 8px;
        }

        &.next {
            margin-left: 8px;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .pages {
        & &-items {
            display: block;
            padding: 2px 40px 0;
            position: relative;
            white-space: normal;
            z-index: 1;
        }

        &-item-previous {
            left: 0;
            position: absolute;
            top: 0;
            z-index: 1;
        }

        &-item-next {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
}
