// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________


.opc-wrapper {
    .form-discount {
        max-width: 500px;
        .field {
            .label {
                @extend .abs-visually-hidden;
            }
        }
    }
}
