//
// Variables
// ---------------------------------------------

$page-padding:      40px;

$columns-count:     3;
$width-label:       140px;
$width-cell:        ($site-width - $width-label) / $columns-count;

//
// Catalog Product Compare
// ---------------------------------------------

.catalog-product-compare-index {

    // Main Wrapper
    .page-main {
        @include section-width;
        position: relative;
        padding: {
            bottom: $page-padding;
            top: $page-padding;
        }
    }

    // Page Title
    .page-title {
        margin-bottom: 50px;
        text-align: center;

        span {
            @extend %h1;
            @extend %font-base;
            text-align: center;
            font-size: rem(30px);
        }
    }


    .comparison-toolbar {
        padding: 13px 3%;
        line-height: 1;
        border: {
            bottom: 1px solid $color-grey-light;
            top: 1px solid $color-grey-light;
        }

        @include bp(min-width, $bp-medium) {
            padding: 13px 0;
        }

        @include clearfix;

        .action.print {
            text-decoration: none;
            float: left;

            &::before {
                @extend %icon-print;
                margin-right: 4px;
            }

            span {
                text-decoration: underline;
            }
        }

        .scroll {
            float: right;

            &::after {
                @extend %icon-chevron_right;
                vertical-align: text-bottom;
                margin-left: 4px;
            }
        }
    }


    //*** Table ***//

    .table-wrapper {
        overflow-x: auto;
    }

    .table-comparison {
        table-layout: fixed;

        // Cells
        .cell {
            @extend %font-base;
            padding: 12px 20px;
            width: $width-cell;
            line-height: 1.78;
            font-size: rem(14px);

            ul {
                padding: 0 0 0 25px;
                margin: 0;
            }
        }

        .cell.label {
            @extend %font-base-bold;
            width: $width-label - 30px;

            @include bp(min-width, $bp-medium) {
                width: $width-label;
            }
        }

        // Rows
        tr {
            background: $color-white;

            &:nth-of-type(even) {
                background: $color-offwhite;
            }
        }
    }


    //*** Cell Specific ***//

    // Remove Row
    .cell.remove {
        padding: 0;

        .remove-label {
            display: none;
        }

        a.action.delete {
            @extend %font-base;
            color: $color-base;
            text-decoration: none;
            font-size: rem(22px);
            margin: 0 10px 0 0;
            height: auto;
            float: left;
            opacity: 0.5;

            &:hover, &:active {
                text-decoration: none;
                opacity: 1;
            }

            &::before {
                @extend %icon-multiply;
            }

            span {
                display: none;
            }
        }
    }


    // Photo
    .product-item-photo {

        .photo.image {
            margin-bottom: 30px;
        }

        .product-badge {
            display: none;
        }
    }


    // Name
    .product-item-name {
        display: block;
    }


    // Reviews
    .product-reviews-summary {
        margin: 0;
    }


    // Price Box
    .price-box {
        @extend %font-inherit;

        .price {
            @extend %font-inherit;
        }

        .special-price, .old-price {
            margin-right: 10px;
        }
    }


    // Add to Cart & Project List
    .actions-primary {
        display: block;
        margin: 0;
    }


    .actions-secondary {
        margin: 0;

        a.action.towishlist {
            margin-top: 5px;
            border: none;
            float: left;
            padding: 0;
            width: auto;

            &::before {
                @extend %icon-file;
                font-size: rem(14px);
                padding-right: 5px;
            }

            span::before {
                display: none;
            }
        }
    }


    //*** Hidden Elements ***//

    .table-caption {
        display: none;
    }
}