// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

@include lib-font-face(
    $family-name: $icons__font-name,
    $font-path: $icons__font-path,
    $font-weight: normal,
    $font-style: normal
);

%font-icomoon {
    font-family: 'icomoon';
}

%icon-facebook {
    content: '\e914';
    @extend %font-icomoon;
}

%icon-twitter {
    content: '\e913';
    @extend %font-icomoon;
}

%icon-pinterest {
    content: '\e90f';
    @extend %font-icomoon;
}

%icon-instagram {
    content: '\e915';
    @extend %font-icomoon;
}

%icon-chevron_left {
    content: '\e904';
    @extend %font-icomoon;
}

%icon-chevron_right {
    content: '\e903';
    @extend %font-icomoon;
}

%icon-chevron_up {
    content: '\e906';
    @extend %font-icomoon;
}

%icon-chevron_down {
    content: '\e905';
    @extend %font-icomoon;
}

%icon-repeat {
    content: '\e908';
    @extend %font-icomoon;
}

%icon-checkmark {
    content: '\e909';
    @extend %font-icomoon;
}

%icon-x {
    content: '\e918';
    @extend %font-icomoon;
}

%icon-cart {
    content: '\e900';
    @extend %font-icomoon;
}

%icon-minus {
    content: '\e901';
    @extend %font-icomoon;
}

%icon-plus {
    content: '\e902';
    @extend %font-icomoon;
}

%icon-location {
    content: '\e907';
    @extend %font-icomoon;
}

%icon-phone {
    content: '\e908';
    @extend %font-icomoon;
}

%icon-chart {
    content: '\e90a';
    @extend %font-icomoon;
}

%icon-play {
    content: '\e90b';
    @extend %font-icomoon;
}

%icon-envelope-o {
    content: '\e90c';
    @extend %font-icomoon;
}

%icon-envelope {
    content: '\e90d';
    @extend %font-icomoon;
}

%icon-print {
    content: '\e90e';
    @extend %font-icomoon;
}

%icon-file {
    content: '\e910';
    @extend %font-icomoon;
}

%icon-pdf {
    content: '\e917';
    @extend %font-icomoon;
}

%icon-user {
    content: '\e911';
    @extend %font-icomoon;
}

%icon-search {
    content: '\e912';
    @extend %font-icomoon;
}

%icon-bars {
    content: '\e92c';
    @extend %font-icomoon;
}

%icon-multiply {
    content: '\00d7';
}