// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                          : 18px;
$checkout-progress-bar__font-weight                        : $font-weight__light;
$checkout-progress-bar__margin                             : $indent__base;

$checkout-progress-bar-item__background-color              : $color-gray-middle1;
$checkout-progress-bar-item__border-radius                 : 6px;
$checkout-progress-bar-item__color                         : $primary__color;
$checkout-progress-bar-item__margin                        : $indent__s;
$checkout-progress-bar-item__transition                    : background 0.3s;
$checkout-progress-bar-item__width                         : 185px;

$checkout-progress-bar-item__active__background-color      : $color-orange-red1;
$checkout-progress-bar-item__complete__color               : $link__color;
$checkout-progress-bar-item__hover__background-color       : darken($checkout-progress-bar-item__background-color, 5%);

$checkout-progress-bar-item-element__width                 : 38px;
$checkout-progress-bar-item-element__height                : $checkout-progress-bar-item-element__width;

$checkout-progress-bar-item-element-outer-radius__width    : 6px;
$checkout-progress-bar-item-element-inner__background-color: $page__background-color;
$checkout-progress-bar-item-element-inner__color           : $checkout-progress-bar-item__color;
$checkout-progress-bar-item-element-inner__width           : $checkout-progress-bar-item-element__width - ( $checkout-progress-bar-item-element-outer-radius__width * 2 );
$checkout-progress-bar-item-element-inner__height          : $checkout-progress-bar-item-element-inner__width;
$checkout-progress-bar-item-element-inner__active__content : $icon-checkmark;



//
//  Common
//  _____________________________________________

//
//  Checkout Progress Bar
//  ---------------------------------------------

.opc-progress-bar {
    @extend .abs-reset-list;
    display: none;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .opc-progress-bar {
        margin: $checkout-progress-bar__margin 0 $checkout-progress-bar__margin;
        counter-reset: i;
        display: block;
        font-size: 0;
        text-align: center;
    }

    .opc-progress-bar-item {
        margin: 0 0 $checkout-progress-bar-item__margin;
        width: $checkout-progress-bar-item__width;
        display: inline-block;
        position: relative;
        vertical-align: top;

        &:before { // Horizontal line
            background: $checkout-progress-bar-item__background-color;
            top: $checkout-progress-bar-item-element__width / 2;
            transition: $checkout-progress-bar-item__transition;
            content: '';
            height: 7px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:first-child {
            &:before {
                border-radius: $checkout-progress-bar-item__border-radius 0 0 $checkout-progress-bar-item__border-radius;
            }
        }

        &:last-child {
            &:before {
                border-radius: 0 $checkout-progress-bar-item__border-radius $checkout-progress-bar-item__border-radius 0;
            }
        }

        & > span {
            display: inline-block;
            padding-top: 45px;
            width: 100%;
            word-wrap: break-word;

            @include lib-typography(
                $_color      : $checkout-progress-bar-item__background-color,
                $_font-family: inherit,
                $_font-size  : $checkout-progress-bar__font-size,
                $_font-style : inherit,
                $_font-weight: $checkout-progress-bar__font-weight,
                $_line-height: inherit
            );

            &:before,
            &:after {
                background: $checkout-progress-bar-item__background-color;
                height: $checkout-progress-bar-item-element__height;
                margin-left: -($checkout-progress-bar-item-element__width / 2);
                transition: $checkout-progress-bar-item__transition;
                width: $checkout-progress-bar-item-element__width;
                border-radius: 50%;
                content: '';
                left: 50%;
                position: absolute;
                top: 0;
            }

            &:after { // Item element inner
                background: $checkout-progress-bar-item-element-inner__background-color;
                height: $checkout-progress-bar-item-element-inner__height;
                margin-left: (-$checkout-progress-bar-item-element-inner__width / 2);
                top: $checkout-progress-bar-item-element-outer-radius__width;
                width: $checkout-progress-bar-item-element-inner__width;
                content: counter(i);
                counter-increment: i;
                @include lib-typography(
                    $_color      : $checkout-progress-bar-item-element-inner__color,
                    $_font-family: inherit,
                    $_font-size  : $checkout-progress-bar__font-size,
                    $_font-style : inherit,
                    $_font-weight: $font-weight__semibold,
                    $_line-height: inherit
                );
            }
        }

        &._complete {
            cursor: pointer;

            &:hover {
                &:before {
                    background: $checkout-progress-bar-item__hover__background-color;
                }

                > span {
                    &:before {
                        background: $checkout-progress-bar-item__hover__background-color;
                    }
                }
            }

            & > span {
                color: $checkout-progress-bar-item__complete__color;

                &:after {
                    font-family: $icons__font-name;
                    content: $checkout-progress-bar-item-element-inner__active__content;
                }
            }
        }

        &._active {
            &:before {
                background: $checkout-progress-bar-item__active__background-color;
            }

            & > span {
                color: $checkout-progress-bar-item__color;

                &:before {
                    background: $checkout-progress-bar-item__active__background-color;
                }

                &:after {
                    font-family: $icons__font-name;
                    content: $checkout-progress-bar-item-element-inner__active__content;
                }
            }
        }
    }
}
