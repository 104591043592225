html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: border-box;
}

body {
    height: 100%;
    width: 100%;
}

%corners__round {
    border-radius: 4px;
}

img {
    &.corners__round {
        @extend %corners__round;
    }

    &.centered,
    &.center {
        @extend %block-center;
    }
}

.clearfix {
    @include clearfix;
}