// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//  Common
//  _____________________________________________
.page-product-giftcard {
    .giftcard-amount {
        text-align: left;
        margin-bottom: 0;
        clear: both;
        border-top: 1px $color-gray-light5 solid;
        padding-top: 15px;

        .field:last-child {
            padding-bottom: $form-field__vertical-indent;
        }
    }

    .product-info-main {
        .price-box {
            .old-price {
                font-size: 20px;
            }

            .price-container.price-final_price {
                & > span {
                    display: block;
                    margin-bottom: $indent__xs;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .price {
                    font-size: 22px;
                    line-height: 22px;
                    font-weight: $font-weight__semibold;
                }
            }

            & + .giftcard.form {
                border-top: 1px $color-gray-light5 solid;
                padding-top: 15px;
            }
        }
    }

    .product-info-stock-sku {
        display: block;
    }

    .product-add-form {
        clear: none;
        border: 0;
        padding-top: 0;

        .product-options-wrapper .field:not(.text) {
            margin-top: $form-field__vertical-indent;
            margin-bottom: $form-field__vertical-indent;
        }
    }

    .giftcard.form {
        clear: both;
    }

    .price-box {
        margin-bottom: 10px;
    }

    .product-info-price {
        float: right;
        width: auto;
        display: block;
        white-space: nowrap;
        border: 0;
    }

    .fieldset.giftcard {
        & > .legend {
            @extend .abs-visually-hidden;
        }

        & > .legend + br {
            @extend .abs-no-display;
        }
    }
}
//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .cart-discount {
        .giftcard .content {
            padding-bottom: 35px;
        }

        .actions-toolbar {
            .secondary {
                bottom: 17px;
            }
        }
    }

    .field.open-amount {
        width: 100%;
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .page-product-giftcard {
        .product-info-main {
            .price-box {
                .price-container.price-final_price {
                    font-size: $font-size__xl;

                    .price {
                        font-size: 36px;
                        line-height: 36px;
                    }
                }
            }
        }

        .product-add-form {
            .field:not(.text) {
                @include lib-form-field( $_type: block, $_column: true );

                &:nth-child(odd) {
                    padding-right: 25px;
                }

                &:nth-child(even) {
                    padding-right: 0;
                }
            }

            .product-options-wrapper {
                .field:not(.date) > .control {
                    width: auto;
                }
            }
        }
    }
}
