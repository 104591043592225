// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//    Common
//--------------------------------------



.form-add-invitations {
    @extend .abs-add-fields;

    .fields {
        .label {
            @extend .abs-visually-hidden;
        }
    }

    .additional,
    .field.message {
        margin-top: $form-field__vertical-indent;
    }

    .action {
        &.remove {
            top: 4px;
        }

        &.add {
            @include lib-button-icon(
                $_icon-font-content: $icon-expand,
                $_icon-font-text-hide: true,
                $_icon-font-size: 18px
            );
            line-height: normal;
            padding: 4px;
            width: auto;
        }
    }
}

//
//    Mobile
//--------------------------------------
@include max-screen($screen__s) {
    .form-add-invitations {
        .action {
            &.remove {
                @include lib-button-icon(
                    $_icon-font-content: $icon-trash,
                    $_icon-font-text-hide: true,
                    $_icon-font-size: 18px,
                    $_icon-font-color: $minicart-icons-color
                );
            }
        }
    }
}

//
//    Desktop
//--------------------------------------
@include min-screen($screen__s) {
    .form-add-invitations {
        // @extend .abs-add-fields-desktop;
    }
}

@include min-screen($screen__m) {
    .table-invitations {
        .col {
            width: 50%;
        }
    }
}
