// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//    Common
//--------------------------------------

.actions-toolbar {
    & > .primary,
    & > .secondary {
        text-align: right;
        margin: 0 0 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & > .secondary {
        .action.back {
            display: none;
        }
    }
}
