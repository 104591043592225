//
// Variables
// ---------------------------------------------


//
// Contact Page
// ---------------------------------------------

.contact-index-index {

    .page-main {
        padding-top: 50px;

        @include bp(min-width, $bp-medium) {
            max-width: 800px;
        }

        .legend {
            font-weight: $font-bold;
        }

        .field {
            float: none;
            width: 100%;
        }
    }

    .column:not(.sidebar-main) .form.contact {
        float: none;
        width: 100%;
    }
}