// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________
$gift-wrapping__border-color                : $color-gray-light5;
$gift-wrapping__border-width                : $border-width__base;

$gift-wrapping-preview__active__border-color: $color-gray20;
$gift-wrapping-preview__active__border-width: 3px;

$gift-wrapping-thumbnail__height            : 4rem;
$gift-wrapping-thumbnail__width             : $gift-wrapping-thumbnail__height;

$gift-wrapping-no-image__background         : $color-gray95;
$gift-wrapping-no-image-icon__color         : $color-gray-darken4;
$gift-wrapping-no-image-icon__font-size     : 22px;

//
//  Common
//  _____________________________________________
.gift-wrapping-info {
    font-weight: $font-weight__light;
    font-size: $font-size__l;
    margin: $indent__base 0;
}
.gift-wrapping-list {
    @extend .abs-add-clearfix;
    list-style: none;
    margin: $indent__base 0 $indent__s;
    padding: 0;
    .no-image {
        background: $gift-wrapping-no-image__background;
        @include lib-icon-font(
            $icon-present,
            $_icon-font-size          : $gift-wrapping-no-image-icon__font-size,
            $_icon-font-line-height   : $gift-wrapping-thumbnail__height,
            $_icon-font-color         : $gift-wrapping-no-image-icon__color,
            $_icon-font-position      : after,
            $_icon-font-text-hide     : true,
            $_icon-font-vertical-align: middle
        );
        &:after {
            display: block;
        }
    }
}
.gift-wrapping-item > span,
.gift-wrapping-preview {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.gift-wrapping-item {
    float: left;
    margin: 0 $indent__s $indent__s 0;
    &:last-child {
        margin-right: 0;
    }
    &._active {
        span {
            &:before {
                border: $gift-wrapping-preview__active__border-width solid $gift-wrapping-preview__active__border-color;
                box-sizing: border-box;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                width: 100%;
                z-index: 2;
            }
        }
    }
    & > span {
        height: $gift-wrapping-thumbnail__height;
        width: $gift-wrapping-thumbnail__width;
    }
}
.gift-wrapping-title {
    margin: $indent__s 0;
    .action-remove {
        @extend .abs-action-button-as-link;
        margin: -$indent__xs 0 0 $indent__xs;
        @include lib-icon-font(
            $icon-trash,
            $_icon-font-size        : 18px,
            $_icon-font-line-height : 20px,
            $_icon-font-text-hide   : true,
            $_icon-font-color       : $minicart-icons-color,
            $_icon-font-color-hover : $primary__color,
            $_icon-font-color-active: $minicart-icons-color
        );
    }
}
.gift-options-content {
    .fieldset {
        .field {
            margin: $indent__s 0;
        }
    }
}
.gift-wrapping-summary {
    padding-right: 7rem;
    .gift-wrapping-title {
        border: 0;
        display: inline-block;
        padding: 0;
    }
}
.opc-wrapper {
    .fieldset.gift-message .field.gift-wrapping {
        padding: 0;
        width: 100%;
    }
}
.opc-wrapper .data.table,
.table-order-items {
    .gift-wrapping {
        margin: $indent__base 0 0;
        .title {
            float: none;
        }
        .content {
            @extend .abs-add-clearfix;
            margin: 0;
            & > img {
                float: left;
                margin: 0 $indent__s 0 0;
                max-height: 50px;
                max-width: 50px;
            }
        }
    }
}
.gift-options,
.gift-summary {
    .price-box {
        margin-left: 22px;
    }
    .regular-price {
        &:before {
            content: attr(data-label) ": ";
        }
        .price {
            font-weight: $font-weight__bold;
        }
    }
    @extend .abs-adjustment-incl-excl-tax;
}
//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .gift-wrapping {
        border-bottom: $gift-wrapping__border-width solid $gift-wrapping__border-color;
        margin-bottom: $indent__base;
        padding-bottom: $indent__s;
    }
    .gift-wrapping-list {
        width: 100%;
    }
    .gift-wrapping-summary {
        & + .gift-message-summary {
            padding-right: 0;
        }
    }
    .gift-options-cart-item {
        float: left;
    }
    .gift-wrapping-preview {
        img {
            width: 100%;
        }
    }
    .item-actions {
        .actions-toolbar {
            .gift-options {
                .gift-message,
                .gift-wrapping {
                    display: block;
                }
            }
        }
    }
}
@include min-screen($screen__m) {
    .data.table {
        .gift-wrapping {
            .content,
            .nested {
                @extend .abs-add-clearfix-desktop;
            }
        }
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .gift-wrapping {
        box-sizing: border-box;
        float: left;
        padding-right: $indent__base;
        width: 50%;
        & + .gift-message {
            border-left: $gift-wrapping__border-width solid $gift-wrapping__border-color;
            box-sizing: border-box;
            float: left;
            padding-left: 4.5rem;
            width: 50%;
        }
    }
}
