//
// Variables
// _____________________________________________

$tabs-height:   30px;

//
// Common
// _____________________________________________

.product.data.items {
    position: relative;
    margin: 0 0 60px 0;
    padding: 0;

    @include clearfix;

    > .item.title {
        line-height: $tabs-height;
        height: $tabs-height;
        margin: 0 5px 0 0;
        display: block;
        float: left;
        width: auto;
        padding: 0;

        a {
            background: $color-white;
            text-decoration: none;
            color: $color-black-dark;
            padding: 0 15px;
            display: block;
            border: 1px solid $product-details-border;

            &:hover {
                background: $product-details-background-active;
                border: 1px solid $product-details-border-active;
            }
        }

        &.active a {
            background: $product-details-background-active;
            border: 1px solid $product-details-border-active;
        }

        .counter {
            color: $color-white;
        }
    }

    > .item.content {
        border: 1px solid $product-details-border;
        margin: $tabs-height 0 0 -100%;
        padding: 20px;
        float: right;
        width: 100%;

        @include clearfix;
    }
}