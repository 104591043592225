// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//    Common
//--------------------------------------

.block-reward-info {
    .reward-balance {
        font-size: 18px;
        margin-bottom: $indent__m;
    }

    .reward-rates,
    .reward-limit,
    .reward-expiring {
        &:not(:last-child) {
            margin-bottom: $indent__m;
        }

        .title {
            display: inline-block;
            margin-bottom: $indent__s;
        }
    }
}

//
//    Mobile
//--------------------------------------
@include max-screen($screen__s) {
    .table-wrapper.reward-history {
        // TO DO - The selector ".abs-account-table-margin-mobile" was not found.
        // @extend .abs-account-table-margin-mobile !optional;
    }
}

//
//    Desktop
//--------------------------------------
@include min-screen($screen__m) {
    .table-wrapper.reward-history {
        // TO DO - The selector ".abs-account-table-margin-desktop" was not found.
        // @extend .abs-account-table-margin-desktop !optional;
    }
}
