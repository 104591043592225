// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$checkout-summary__background-color: $color-white-smoke;
$checkout-summary__padding: 22px $indent__l;

$checkout-summary-title__margin: $indent__s;
$checkout-summary-mark-value__color: $color-gray60;

$checkout-summary-items__max-height: 370px;
$checkout-summary-items__padding: 15px;

//
//  Common
//  _____________________________________________


//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary {
    // should extend only the class below like less extend('.className')
    // not others selectors like "div.className" etc.
    @extend .abs-add-box-sizing;
    background: $checkout-summary__background-color;
    margin: 0;
    padding: $checkout-summary__padding;


    & > .title {
        // should extend only the class below like less extend('.className')
        // not others selectors like "div.className" etc.
        @extend .abs-checkout-title;
        display: block;
    }

    //  Totals table
    .table-totals {
        // should extend only the class below like less extend('.className')
        // not others selectors like "div.className" etc.
        @extend .abs-sidebar-totals;
        border-bottom: $border-width__base solid $border-color__base;
    }

    .mark {
        .value {
            color: $checkout-summary-mark-value__color;
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                font-size: 14px;
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        & > .title {
            border-bottom: $border-width__base solid $border-color__base;
            padding: $indent__s $indent__xl $indent__s 0;
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size: 12px,
                $_icon-font-line-height: 12px,
                $_icon-font-text-hide: true,
                $_icon-font-margin: 3px 0 0,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            margin-bottom: 0;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                top: $indent__s;
                font-size: 40px;
            }

            strong {
                font-size: 18px;
                font-weight: $font-weight__light;
                margin: 0;
            }
        }

        &.active {
            & > .title {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        .product {
            position: relative;
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        margin: 0 (-$checkout-summary-items__padding) 0 0;
        max-height: $checkout-summary-items__max-height;
        padding: $checkout-summary-items__padding $checkout-summary-items__padding 0 0;
        border: 0;
    }

    .column.main & {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }

    .product-item {
        .product-item-inner {
            display: table;
            margin: 0 0 $indent__s;
            width: 100%;
        }

        .product-item-name-block {
            display: table-cell;
            padding-right: $indent__xs;
            text-align: left;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            font-size: 16px;
            font-weight: $font-weight__regular;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                .price {
                    font-size: 10px;
                }
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-block-summary {
        & > .title {
            border-bottom: 0;
            font-size: $checkout-step-title-mobile__font-size;
            margin-bottom: $indent__base;
            padding-bottom: 0;
        }

        .totals {
            // should extend only the class below like less extend('.className')
            // not others selectors like "div.className" etc.
            // TO DO - The selector ".abs-sidebar-totals-mobile" was not found.
            // @extend .abs-sidebar-totals-mobile !optional;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
