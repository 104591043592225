//
// Logout Page
// ---------------------------------------------

.customer-account-logoutsuccess {

    .page-main {
        @include section-width();
        padding: {
            bottom: 100px;
            top: 100px;
        }
    }

    h1.page-title {
        @extend %h1;
        text-align: center;
    }

    .column.main {
        text-align: center;
        padding: 0;
    }
}