// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin: $indent__base;
$checkout-wrapper__columns: 8;

$checkout-step-title__border: $border-width__base solid $color-gray80;
$checkout-step-title__font-size: 26px;
$checkout-step-title__font-weight: $font-weight__light;
$checkout-step-title__padding: $indent__s;

$checkout-step-title-mobile__font-size: 18px;

$checkout-step-content-mobile__background: $color-gray-light01;
$checkout-step-content-mobile__margin-s: 15px;
$checkout-step-content-mobile__margin: $indent__base (-$checkout-step-content-mobile__margin-s) $checkout-step-content-mobile__margin-s;

//
//  Common
//  _____________________________________________


.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    margin: 0 0 $checkout-wrapper__margin;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
        border-bottom: $checkout-step-title__border;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .page-header {
        padding: 20px 0;
        margin: 0;
    }
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin: 0 auto;
        width: 100%;
    }
    .opc-estimated-wrapper {
        margin-top: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            font-size: $checkout-step-title-mobile__font-size;
            border-bottom: 0;
        }

        .step-content {
            margin: 0 0 $checkout-step-content-mobile__margin-s;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }
}

.checkout-success {
    padding: 20px;
}