// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//    Common
//--------------------------------------
.data.switch .counter {
    color: $text__color__muted;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

.rating-summary {
    @include lib-rating-summary();
}

.product-reviews-summary,
.table-reviews {
    .rating-summary {
        @include lib-rating-summary-label-hide();
    }
}

.review-control-vote {
    @include lib-rating-vote();

    &:before {
        content: $rating-icon__content;
    }
}
//
//    Review product page
//--------------------------------------
.review {
    &-toolbar {
        padding: 0;
        border-top: 1px solid $color-gray79;
        margin: 0 0 $indent__base;
        text-align: center;

        &:first-child {
            display: none;
        }

        .pages {
            display: block;
            text-align: center;
            border-bottom: 1px solid $color-gray79;
            padding: $indent__base 0;
        }

        .limiter {
            display: none;
        }
    }

    &-add {
        .block-title {
            display: none;
        }
    }

    &-form {
        .action.submit.primary {
            @include lib-button-revert-secondary-color();
            @include lib-button-revert-secondary-size();
            margin: 0;
        }

        .actions-toolbar .actions-primary {
            padding: 0;
        }
    }

    .fieldset &-legend.legend {
        line-height: 1.3;
        border-bottom: 0;
        padding: 0;

        span {
            margin: 0;
        }

        strong {
            display: block;
            font-weight: 600;
        }
        margin-bottom: $indent__base;
    }

    .fieldset &-field-ratings {
        > .label {
            margin-bottom: $indent__s;
            font-weight: 600;
            padding: 0;
        }
    }

    &-control-vote label:before,
    &-control-vote:before {
        font-size: 32px;
        line-height: 32px;
        height: 32px;
        letter-spacing: 10px;
    }

    &-field-rating {
        .label {
            font-weight: 600;
        }

        .control {
            margin-top: $indent__s;
            margin-bottom: 1.2 * $indent__xl;
        }
    }

    &-list {
        .block-title strong {
            @include lib-heading(h2);
        }
        margin-bottom: $indent__l;
    }

    &-item {
        padding: $indent__base 0;
        border-bottom: 1px solid $color-gray79;
        margin: 0;
        @include lib-clearer();

        &:last-child {
            border-width: 0;
        }
    }

    &-ratings {
        // TO DO - The selector ".abs-rating-summary" was not found.
        // @extend .abs-rating-summary !optional;
        display: table;
        max-width: 100%;
        margin-bottom: $indent__s;
    }

    &-author {
        display: inline;

        .review-details-value {
            font-weight: 400;
        }
    }

    &-date {
        display: inline;

        .review-details-label {
            @extend .abs-visually-hidden;
        }
    }

    &-title {
        @include lib-heading(h3);
        margin: 0 0 $indent__base;
    }

    &-content {
        margin-bottom: $indent__base;
    }
}

.page-main {
    .column {
        .review-add {
            margin-bottom: 0;
        }
    }
}
//
//    Desktop
//--------------------------------------
@include min-screen($screen__s) {
    $review-ratings-left: 280px;

    .review {
        &-form {
            max-width: 500px;
        }

        &-ratings {
            max-width: $review-ratings-left - $indent__xl;
            float: left;
            margin-bottom: 0;
        }

        &-ratings ~ &-content,
        &-ratings ~ &-details {
            margin-left: $review-ratings-left;
        }
    }

    .review {
        $reviews-indent-desktop: 30px;

        &-toolbar {
            margin: 0 0 $reviews-indent-desktop;

            .pages {
                padding: $reviews-indent-desktop 0;
            }
        }

        .fieldset &-legend.legend {
            margin-bottom: $reviews-indent-desktop;
        }

        &-item {
            padding: $reviews-indent-desktop 0;
        }

        &-title {
            margin: 0 0 $reviews-indent-desktop;
        }
    }
}
//
//    Common
//--------------------------------------
.data.table.reviews {
    .rating-summary {
        margin-top: -4px;
    }
}

.customer.review.view {
    .review.title,
    .ratings.summary.items,
    .product.details {
        margin-bottom: $indent__base;
    }
}

.product-reviews-summary {
    display: table;
    margin: $indent__s 0 12px;

    .products.wrapper.list & {
        margin: 0 auto;
    }

    &.empty {
        margin-left: 0;
    }

    .rating-summary {
        position: relative;
        left: (-$indent__xs);
        display: table-cell;
        vertical-align: middle;
        text-align: left;
    }

    .reviews-actions {
        display: table-cell;
        vertical-align: middle;
        line-height: $rating-icon__font-size;

        a {
            margin-right: $indent__xs;
        }
    }
}

.product-info-main,
.customer-review {
    .rating-summary {
        margin-right: 30px;
    }
}
//
//    Desktop
//--------------------------------------
@include min-screen($screen__m) {
    .product-reviews-summary {
        margin-bottom: $indent__s + $indent__xs;

        .products.wrapper.list & {
            margin: 0;
        }

        .reviews-actions {
            font-size: $font-size__base;
            margin-top: 3px;
        }
    }
}
//
//    Common
//--------------------------------------
//
//    Account
//--------------------------------------
.block-reviews-dashboard {
    .items {
        .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .product-name {
        display: inline-block;

        &:not(:last-child) {
            margin-bottom: $indent__xs;
        }
    }

    .rating-summary {
        .label {
            font-weight: $font-weight__semibold;
            margin-right: $indent__s;
        }
    }
}

.table-reviews,
.block-reviews-dashboard {
    .product-name {
        font-weight: $font-weight__regular;
    }
}

.table-reviews {
    .rating-summary {
        display: inline-block;
        vertical-align: baseline;
    }
}

//
//    Mobile
//--------------------------------------
@include max-screen($screen__s) {
    .account {
        .table-reviews {
            .col.actions {
                // TO DO - The selector ".abs-col-no-prefix" was not found.
                // @extend .abs-col-no-prefix !optional;
            }
        }
    }
}

//
//    Desktop
//--------------------------------------
@include min-screen($screen__m) {
    .block-reviews-dashboard {
        .product-name {
            // TO DO - The selector ".abs-account-block-font-size" was not found.
            // @extend .abs-account-block-font-size !optional;
        }
    }
}
//
//    Common
//--------------------------------------
.customer-review {
    .product-details {
        @extend .abs-add-clearfix;
        @extend .abs-margin-for-blocks-and-widgets;

        .rating-average-label {
            @extend .abs-visually-hidden;
        }
    }

    .product-media {
        width: 30%;
        max-width: 285px;
        float: left;
        margin-right: 3%;
    }

    .review-details {
        margin: 0;

        .title {
            // TO DO - The selector ".abs-account-title" was not found.
            // @extend .abs-account-title !optional;
        }

        .customer-review-rating {
            // TO DO - The selector ".abs-rating-summary" was not found.
            // @extend .abs-rating-summary !optional;
            margin-bottom: $indent__base;

            .item {
                margin-bottom: $indent__s;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .review-title {
            @include lib-heading(h3);
            font-weight: $font-weight__semibold;
            margin-bottom: $indent__base;
        }

        .review-content {
            margin: 0 0 $indent__base;
        }

        .review-date {
            color: $primary__color__light;
        }
    }
}
//
//    Mobile
//--------------------------------------
@include max-screen($screen__s) {
    .customer-review {
        .product-name {
            margin-bottom: $indent__xs;
        }

        .product-reviews-summary {
            .rating-summary {
                display: block;
                margin-bottom: $indent__xs;
            }
        }
    }
}
//
//    Desktop
//--------------------------------------
@include min-screen($screen__m) {
    .customer-review {
        .product-details {
            // TO DO - The selector ".abs-margin-for-blocks-and-widgets-desktop" was not found.
            // @extend .abs-margin-for-blocks-and-widgets-desktop !optional;
        }
    }
}
