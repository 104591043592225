// Header Links
.header.links {
    text-align: right;
    list-style: none;
    display: none;
    padding: 0;
    margin: 0;

    @include bp(min-width, $bp-medium) {
        display: block;
    }

    li {
        display: inline-block;
        margin-left: 10px;
    }
}