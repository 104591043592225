//
// Page Grid View
// ---------------------------------------------

.products-grid {

    // Items List
    .product-items {
        display: flex;
        flex-wrap: wrap;
    }

    // Individual Item
    .product-item {
        padding: 20px;
        width: 50%;
        margin: 0;

        @include bp(min-width, $bp-small) {
            width: calc(100% / 3);
        }
    }

    // Product Name
    .product-item-name {
        display: block;
        margin: 10px 0;
    }

    // Actions
    .actions-primary {
        .action.tocart {
            @extend %block-center;
            margin: {
                bottom: 10px;
                top: 10px;
            }
        }
    }

    .actions-secondary {
        .action {
            text-align: center;
            display: block;
        }
    }

    // Hidden Elements
    .swatch-input {
        display: none;
    }
}