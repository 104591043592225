//
// Variables
// ---------------------------------------------

$cms-padding: 40px;


//
// Import
// ---------------------------------------------

@import "modules/blocks";