//
// Variables
// ---------------------------------------------

$footer-links:          70%;
$footer-newsletter:     100% - $footer-links;

//
// Page Footer
// ---------------------------------------------

.page-footer {
    background: $color-grey-lighter;
    border-top: 10px solid $color-primary-blue;
    padding: 0 0 20px;

    @include bp(min-width, $bp-medium) {
        padding: 20px 0;
    }

    // Layout
    .footer.content {
        @include clearfix;
        display: flex;
        flex-direction: column;

        @include bp(min-width, $bp-small) {
            flex-direction: row;
        }
    }

    .footer.links {
        list-style: none;
        padding: 0;
        width: 100%;
        width: calc(100% + 30px);
        display: inline-block;
        margin: -10px -15px 15px;

        @include bp(min-width, $bp-small) {
            width: 100%;
            margin: 7px 0 0 0;
        }
        
        li {
            margin: 0;
            font-size: 0.8rem;
            border-bottom: 2px solid $color-black;

            @include bp(min-width, $bp-small) {
                float: left;
                margin: 7px 10px 7px 0;
                border: none;
            }
            
            a {
                display: block;
                width: 100%;
                color: $color-base;
                padding: 10px;
                color: $color-white;
                background: $color-primary-blue;
                font-weight: 700;
                font-size: 1rem;
                text-align: center;
                text-decoration: none;

                @include bp(min-width, $bp-small) {
                    padding: 0;
                    display: inline;
                    text-decoration: underline;
                    background: none;
                    color: $color-black;
                    font-weight: 300;
                    font-size: .8rem;
                }
            }
        }
    }

    .block.newsletter {
        margin-bottom: 20px;

        @include bp(min-width, $bp-medium) {
            width: $footer-newsletter;
            margin-bottom: 0;
            float: right;
        }
    }

    .footer__links-copyright {
        width: 100%;
        font-size: 0.8rem;
        line-height: 18px;

        span {
            display: block;
        }
    }

    .footer__logo {
        max-width: 150px;
        display: block;
        margin: 30px auto 0;

        @include bp(min-width, $bp-small) {
            margin: 15px 0 0;
        }
    }
}
