// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//  Variables
//  _____________________________________________
$gift-message-field-label__color            : $color-gray40;
$gift-item-block__border-color              : $color-gray-light5;
$gift-item-block__border-width              : $border-width__base;
$gift-item-block-title__color               : $color-blue1;
$gift-item-block-title-icon__content        : $icon-down;
$gift-item-block-title-icon__active__content: $icon-up;
$gift-item-block-title-icon__color          : $color-gray52;
$gift-item-block-title-icon__font-size      : 18px;
$gift-item-block-title-icon__line-height    : 20px;
$gift-item-block-title-icon__margin         : 0 0 0 $indent__s;
//
//  Common
//  _____________________________________________
.gift-message {
    .field {
        .label {
            color: $gift-message-field-label__color;
            font-weight: $font-weight__regular;
        }
    }
}
.cart.table-wrapper {
    .item-gift {
        &._active {
            border-top: $gift-item-block__border-width solid $gift-item-block__border-color;
        }
        .action {
            margin-right: 0;
        }
    }
}
.gift-options {
    margin: $indent__base 0;
    .actions-toolbar {
        .action-cancel {
            @extend .abs-action-button-as-link;
            display: none;
        }
    }
}
.gift-options-title {
    font-weight: $font-weight__semibold;
    margin: 0 0 $indent__base;
}
.gift-options-content {
    .fieldset {
        margin: 0 0 $indent__base;
    }
}
.gift-summary {
    position: relative;
    .actions-toolbar {
        & > .secondary {
            .action {
                margin: 0 0 0 $indent__base;
                width: auto;
            }
        }
    }
    .action-delete,
    .action-edit {
        @extend .abs-action-button-as-link;
        @include lib-icon-font(
            $icon-edit,
            $_icon-font-size        : 18px,
            $_icon-font-line-height : 20px,
            $_icon-font-text-hide   : true,
            $_icon-font-color       : $minicart-icons-color,
            $_icon-font-color-hover : $primary__color,
            $_icon-font-color-active: $minicart-icons-color
        );
    }
    .action-delete {
        @include lib-icon-font-symbol( $_icon-font-content: $icon-trash );
    }
}
.cart.table-wrapper .gift-summary,
.gift-summary {
    .actions-toolbar {
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
//
//  In-table block
//  ---------------------------------------------
.cart.table-wrapper {
    .gift-content {
        display: none;
        clear: left;
        float: left;
        margin-right: -100%;
        padding: $indent__base 0;
        width: 100%;
        &._active {
            display: table;
            table-layout: fixed;
        }
    }
}
//
//  Collapsible block
//  ---------------------------------------------
.gift-item-block {
    border-bottom: $gift-item-block__border-width solid $gift-item-block__border-color;
    margin: 0;
    &._active {
        .title {
            &:after {
                content: $gift-item-block-title-icon__active__content;
            }
        }
    }
    .title {
        color: $gift-item-block-title__color;
        cursor: pointer;
        @include lib-icon-font(
            $gift-item-block-title-icon__content,
            $_icon-font-size       : $gift-item-block-title-icon__font-size,
            $_icon-font-line-height: $gift-item-block-title-icon__line-height,
            $_icon-font-color      : $gift-item-block-title-icon__color,
            $_icon-font-color-hover: $primary__color,
            $_icon-font-margin     : $gift-item-block-title-icon__margin,
            $_icon-font-position   : after,
            $_icon-font-display    : block
        );
        margin: 0;
        padding: $indent__s $indent__xl $indent__s 15px;
        position: relative;
    }
    .content {
        @extend .abs-add-clearfix;
        padding: $indent__s 0 $indent__base;
        position: relative;
    }
}
//
//  Account order gift message
//  ---------------------------------------------
.order-details-items {
    .block-order-details-gift-message,
    .order-gift-message {
        @extend .abs-add-clearfix;
        dt {
            &:after {
                content: '';
            }
        }
        .label {
            @extend .abs-colon;
        }
        .item-message {
            clear: left;
        }
        .item-options {
            dt {
                font-weight: $font-weight__regular;
                margin: $indent__s 0;
            }
        }
    }
}
.order-details-items {
    .order-items {
        .order-gift-message {
            &:not(.expanded-content) {
                // should extend only the class below like less extend('.className')
                // not others selectors like "div.className" etc.
                @extend .abs-hidden;
            }
            .action.close {
                @extend .abs-no-display;
            }
        }
    }
}
.options-items-container,
.options-order-container {
    margin-bottom: $indent__s;
}
.gift-messages-order {
    margin-bottom: $indent__m;
}
//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .gift-message {
        .actions-toolbar {
            .action-update {
                font-size: 20px;
                padding: 15px;
                width: 100%;
            }
        }
    }
    .gift-item-block {
        border-bottom: 0;
        border-top: $gift-item-block__border-width solid $gift-item-block__border-color;
        .title {
            &:after {
                font-size: 12px;
                position: absolute;
                right: 20px;
                top: 10px;
            }
        }
        .content {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
    .gift-message-summary {
        padding-right: 112px;
    }
    .cart.table-wrapper {
        .action-gift {
            @include lib-icon-font(
                $icon-present,
                $_icon-font-size        : 18px,
                $_icon-font-line-height : 20px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $minicart-icons-color,
                $_icon-font-color-hover : $primary__color,
                $_icon-font-color-active: $minicart-icons-color,
                $_icon-font-position    : after
            );
            &._active {
                border: $border-width__base solid $color-gray-light5;
                border-bottom-color: $color-white;
                margin: -$indent__xs 14px -1px -15px;
                padding: 4px $indent__s 9px 14px;
                &:after {
                    color: $primary__color;
                }
            }
        }
        .gift-content {
            border-top: $border-width__base solid $color-gray-light5;
            margin-right: -$mobile-cart-padding;
            padding-left: $mobile-cart-padding;
            padding-right: $mobile-cart-padding;
            overflow: hidden;
        }
    }
    .gift-options-cart-item {
        & + .action-towishlist {
            left: 43px;
            position: absolute;
        }
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .gift-message {
        .field {
            // TO DO - The selector ".abs-clearfix" was not found.
            // @extend .abs-clearfix !optional;
            @include lib-form-field-type-revert(
                $_type: inline,
                $_type-inline-label-align: left
            );
        }
    }
    .gift-options {
        position: relative;
        z-index: 1;
        .actions-toolbar {
            clear: both;
            padding: 0;
            position: static;
            .secondary {
                float: right;
                .action {
                    float: right;
                    margin-left: $indent__base;
                    margin-right: 0;
                }
                .action-cancel {
                    display: block;
                    float: left;
                    margin-top: 6px;
                }
            }
            &:nth-child(3) {
                &:before {
                    border-left: 1px solid $gift-item-block__border-color;
                    bottom: 50px;
                    content: '';
                    display: block;
                    left: 50%;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    width: 0;
                }
            }
        }
    }
    .gift-options-title {
        font-weight: $font-weight__light;
        font-size: 18px;
    }
    .gift-item-block {
        .title {
            padding: $indent__base 0;
        }
    }
    .item-gift {
        td {
            padding-left: 0;
            padding-right: 0;
        }
    }
    //
    //  In-table block
    //  ---------------------------------------------
    .cart.table-wrapper {
        .action-gift {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 18px,
                $_icon-font-line-height : 20px,
                $_icon-font-color       : $minicart-icons-color,
                $_icon-font-color-hover : $primary__color,
                $_icon-font-color-active: $minicart-icons-color,
                $_icon-font-margin      : 0 0 0 $indent__s,
                $_icon-font-position    : after
            );
            &._active {
                &:after {
                    content: $icon-up;
                }
            }
        }
        .gift-options {
            .actions-toolbar {
                clear: both;
                padding: 0;
                position: static;
            }
        }
    }
    .cart-container {
        .cart-gift-item {
            @extend .abs-shopping-cart-items-desktop;
            margin-bottom: $indent__base;
            .gift-options,
            .gift-summary {
                padding-bottom: $indent__base;
            }
        }
    }
}
