// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________

.bundle-actions {
    margin: 0 0 $indent__l;
    .action.primary.customize {
        @extend .abs-button-l;
        // TO DO - The selector ".abs-button-responsive" was not found.
        // @extend .abs-button-responsive !optional;
    }
}

.bundle-options-container {
    clear: both;
    margin-bottom: $indent__xl;

    .legend.title {
        @include lib-heading(h1);
        border: 0;
        padding: 0;
    }

    .product-add-form {
        display: none;
    }

    .input-text.qty {
        @extend .abs-input-qty;
    }

    .product-options-wrapper {
        margin-bottom: $indent__l;
        .fieldset > .field {
            border-top: 1px solid $color-gray-middle1;
            padding-top: $form-field__vertical-indent;

            &:first-of-type {
                border-top: 0;
                padding-top: 0;
            }
        }

        .fieldset-bundle-options {
            .field.choice {
                .price-notice {
                    @extend .abs-adjustment-incl-excl-tax;
                }
            }
        }

        .field.choice {
            input {
                float: left;
            }

            .label {
                @extend .abs-add-clearfix;
                display: block;
                margin-left: 24px;
            }

            .price-notice {
                @extend .abs-adjustment-incl-excl-tax;
            }

            .price-excluding-tax {
                display: inline-block;
            }
        }
    }

    .action.back {
        @extend .abs-action-button-as-link;
        margin-bottom: $indent__l;
    }

    .block-bundle-summary {
        & > .title {
            margin-bottom: 15px;

            & > strong {
                @include lib-heading(h2);
            }
        }

        & > .title,
        .bundle-summary .subtitle {
            border-bottom: 1px solid $color-gray-middle1;
            margin-bottom: $indent__m;
            padding-bottom: 16px;
        }

        .price-box {
            margin-bottom: $indent__base;

            .price-label {
                display: block;
                margin-bottom: $indent__xs;
            }
        }

        .bundle-summary {
            margin-top: $indent__l;

            &.empty {
                display: none;
            }

            & > .subtitle {
                @include lib-heading(h2);
                display: block;
            }
        }

        .bundle.items {
            @extend .abs-reset-list;

            & > li {
                margin-bottom: $indent__base;
            }
        }

        .box-tocart {
            @extend .abs-box-tocart;

            .action.primary {
                @extend .abs-button-l;
                // TO DO - The selector ".abs-button-responsive" was not found.
                // @extend .abs-button-responsive !optional;
            }
        }

        .product-addto-links {
            text-align: center;

            & > .action {
                // TO DO - The selector ".abs-actions-addto" was not found.
                // @extend .abs-actions-addto !optional;
                margin-right: 5%;

                &.tocompare {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-compare-full,
                        $_icon-font-position: before
                    );
                }
            }
        }

        .product-image-container,
        .product.name,
        .stock {
            @extend .abs-visually-hidden;
        }
    }

    p.required {
        color: $form-field-label-asterisk__color;
    }

    .nested {
        .field.qty {
            @include lib-form-field-type($_type: block);

            .label {
                font-weight: $form-field-label__font-weight;
            }

            &:last-child {
                margin-bottom: 0;
            }

            margin-top: $form-field__vertical-indent;
        }
    }

    .price {
        font-weight: $font-weight__semibold;
    }

    .product-options-bottom {
        border-top: 1px solid $border-color__base;
        clear: left;
        margin: 0 0 $indent__xl;
        padding-top: $indent__base;
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .bundle-actions {
        .action.primary.customize {
            width: auto;
        }
    }

    .bundle-options-container {
        .legend.title {
            font-size: $h1__font-size-desktop;
        }

        .bundle-options-wrapper,
        .product-options-wrapper {
            float: left;
            width: 57%;
        }

        .block-bundle-summary {
            @extend .abs-add-box-sizing-desktop;
            float: right;
            margin-top: 66px;
            padding: $indent__s $indent__base;
            position: relative;
            width: 40%;

            .price-box {
                .price-wrapper,
                .price-wrapper > .price {
                    color: $price-color;
                    font-size: $price-size-desktop;
                    font-weight: $font-weight__semibold;
                    line-height: $price-size-desktop;
                }
            }

            .price-container {
                .weee {
                    color: $price-color;

                    .price {
                        font-size: 12px;
                        font-weight: $font-weight__bold;
                    }
                }
            }

            .price-including-tax + .price-excluding-tax {
                .price {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            .box-tocart {
                .action.primary {
                    margin-right: 1%;
                    width: 49%;
                }
            }

            .product-addto-links {
                text-align: left;
            }
        }

        .bundle-options-wrapper,
        .block-bundle-summary {
            .page-layout-2columns-left &,
            .page-layout-2columns-right &,
            .page-layout-3columns & {
                width: 48%;
            }
        }
    }
}
