// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//  Common
//  _____________________________________________
.form-giftregistry-edit,
.form-giftregistry-share {
    @extend .abs-add-fields;
    .actions-toolbar:not(:last-child) {
        margin-bottom: $indent__xl;
    }
    .fieldset {
        .nested {
            .field:not(.choice) {
                .control {
                    width: 100%;
                }
            }
        }
    }
}
.form.giftregistry.items {
    .actions-toolbar {
        @extend .abs-reset-left-margin;
    }
}
.giftregisty-dropdown {
    @extend .abs-dropdown-simple;
    .action.toggle {
        color: $link__color;
    }
}
.table-giftregistry-items {
    .field.qty .qty {
        @extend .abs-input-qty;
    }
    .product-image {
        margin-right: $indent__s;
        float: left;
    }
}
.form.giftregistry .fieldset {
    .cart-summary & {
        margin: 15px 0 $indent__xs;
        text-align: left;
        .field {
            margin: 0 0 $indent__s;
            .control {
                float: none;
                width: auto;
            }
        }
        .action.primary {
            // TO DO - The selector ".abs-revert-to-action-secondary" was not found.
            // @extend .abs-revert-to-action-secondary !optional;
        }
    }
}
.form-giftregistry-create {
    .fieldset {
        // TO DO - The selector ".abs-forms-margin-small" was not found.
        // @extend .abs-forms-margin-small !optional;
    }
}
.form-giftregistry-edit {
    .field.date {
        @extend .abs-field-date;
    }
}
.form-giftregistry-search {
    margin-bottom: $indent__l * 2;
    .legend {
        // TO DO - The selector ".abs-account-title" was not found.
        // @extend .abs-account-title !optional;
    }
    .fields-specific-options {
        .field {
            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
        }
        .datetime-picker {
            @extend .abs-field-date-input;
        }
    }
}
.block-giftregistry-results {
    .block-title {
        // TO DO - The selector ".abs-account-title" was not found.
        // @extend .abs-account-title !optional;
    }
    .data.table {
        // TO DO - The selector ".abs-table-striped" was not found.
        // @extend .abs-table-striped !optional;
    }
}
.block-giftregistry {
    .actions-toolbar {
        margin: 17px 0 0;
    }
}
.block-giftregistry-shared {
    .item-options {
        .item-value {
            margin: 0 0 $indent__m;
        }
    }
}
.block-giftregistry-shared-items {
    .block-title {
        // TO DO - The selector ".abs-account-title" was not found.
        // @extend .abs-account-title !optional;
    }
    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
    .item {
        .col {
            border: 0;
        }
    }
    th {
        border-bottom: $border-width__base solid $border-color__base;
    }
    .col {
        padding: 11px 10px;
        .price-box {
            display: inline-block;
        }
        .price-wrapper {
            font-size: 18px;
            display: block;
            font-weight: $font-weight__bold;
            white-space: nowrap;
        }
    }
    .qty {
        .qty {
            @extend .abs-input-qty;
        }
    }
    .product {
        .item-options {
            @extend .abs-product-options-list;
            @extend .abs-add-clearfix;
            margin: 0;
        }
        &-item-photo {
            display: block;
            max-width: 65px;
            padding: $indent__s 0;
        }
        &-item-name {
            font-size: 18px;
            display: block;
            margin: 0 0 $indent__xs;
        }
    }
}
//
//  Mobile
//  _____________________________________________
@include max-screen($screen__s) {
    .account {
        .table-giftregistry {
            .col.actions {
                // TO DO - The selector ".abs-col-no-prefix" was not found.
                // @extend .abs-col-no-prefix !optional;
            }
        }
    }
    .table-giftregistry-items {
        .col.product {
            &:before {
                @extend .abs-visually-hidden-mobile;
            }
            @extend .abs-add-clearfix-mobile;
        }
        .field.choice,
        .field.qty {
            display: inline-block;
        }
    }
    .form-giftregistry-search {
        .fieldset {
            margin-bottom: $indent__base;
        }
    }
    .block-giftregistry-results {
        .block-title {
            margin-bottom: 0;
        }
        .table-wrapper {
            border-top: none;
        }
    }
    .block-giftregistry-shared-items {
        .block-title {
            margin-bottom: $indent__s;
        }
        .table-wrapper {
            border-top: 0;
        }
    }
}
@include max-screen($screen__m) {
    //  Shopping cart link
    .cart.table-wrapper {
        .action-giftregistry {
            @include lib-icon-font(
                $icon-gift-registry,
                $_icon-font-size        : 18px,
                $_icon-font-line-height : 20px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $minicart-icons-color,
                $_icon-font-color-hover : $primary__color,
                $_icon-font-color-active: $minicart-icons-color
            );
        }
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .form-giftregistry-edit,
    .form-giftregistry-share {
        @extend .abs-add-fields-desktop;
        // TO DO - The selector ".abs-form-field-column-2" was not found.
        // @extend .abs-form-field-column-2 !optional;
        .fieldset .field {
            width: 45%;
        }
    }

    .form-giftregistry-create {
        // TO DO - The selector ".abs-forms-general-desktop" was not found.
        // @extend .abs-forms-general-desktop !optional;
    }
    .table-giftregistry {
        .col.create {
            white-space: nowrap;
        }
        .col.message {
            width: 40%;
        }
        .col.actions {
            width: 30%;
        }
    }
    .table-giftregistry-items {
        .col.note {
            width: 30%;
        }
        .account .data.table & {
            @include lib-table-bordered( $_table_type: horizontal );
            th {
                border-bottom: 0;
            }
            & > tbody > tr:nth-child(even) {
                & > td,
                & > th {
                    background: none;
                }
            }
        }
    }
    .form-giftregistry-search {
        .fieldset {
            margin-bottom: $form-field__vertical-indent__desktop;
            .field {
                &.lastname,
                &.name {
                    @include lib-form-field-column($_column: true);
                    margin-bottom: auto;
                }
                &.lastname {
                    padding: 0;
                }
            }
        }
    }
    .toolbar-giftregistry-results {
        @extend .abs-pager-toolbar;
    }
    .block-giftregistry-shared {
        .item-options {
            @extend .abs-product-options-list-desktop;
            @extend .abs-add-clearfix-desktop;
        }
    }
    .block-giftregistry-shared-items {
        .column.main & {
            .actions-toolbar {
                @extend .abs-reset-left-margin-desktop;
            }
        }
        .item {
            border-bottom: $border-width__base solid $border-color__base;
            border-top: 0;
        }
        .product {
            &-item-photo {
                display: table-cell;
                max-width: 100%;
                padding: 0 $indent__base 0 0;
                vertical-align: top;
                width: 1%;
            }
            &-item-details {
                display: table-cell;
                vertical-align: top;
                width: 99%;
                word-break: normal;
            }
        }
        .col {
            &.product {
                width: 48%;
            }
            &:not(.product) {
                text-align: center;
            }
            &.price {
                padding-top: 17px;
            }
        }
        .input-text {
            &.qty {
                margin-top: -4px;
            }
        }
    }
}
