//
// Section Variables
// ---------------------------------------------

$account-margin-bottom:     25px;
$account-margin-right:      10px;


//
// Account Page
// ---------------------------------------------

.account .page-main {
    padding-bottom: 60px;

    .page-title {
        padding-top: 15px;
    }
    .page-title-wrapper {
        .order-status {
            font-weight: $font-weight__bold;
        }
    }
    // Account Nav
    .account-nav {

        &.block {
            padding-right: 40px;
            margin-top: 52px;
            @include bp(max-width, $bp-medium) {
                padding-right: 0;
                margin-top: 0;
            }
        }

        .item,
        .item.current {
            border-bottom: 1px solid $color-grey;
            padding: 5px;
            margin: 0;

            &:first-of-type {
                border-top: 1px solid $color-grey;
            }

            a, strong {
                @extend %font-base-bold;
                color: $color-black;
                text-decoration: none;
                font-size: rem(16px);
                letter-spacing: 0;
                display: block;
            }
        }

        .item.current strong {
            color: $color-primary-light;
        }
    }

    .legend {
        @extend %account-title;
        display: block;
        width: 100%;
    }

    // Block
    .block {
        margin-bottom: $account-margin-bottom * 1.5;

        &-title {
            @extend %account-title;

            > .action {
                vertical-align: text-top;
                font-weight: $font-normal;
                display: inline-block;
                font-size: rem(14px);
                margin-left: 5px;
            }
        }

        &-content {
            p:last-of-type {
                margin-bottom: 0;
            }
        }

        .box {
            margin-bottom: $account-margin-bottom;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-actions {
                padding-top: 10px;

                .action {
                    display: inline-block;
                    margin-right: $account-margin-right;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    // Actions Toolbar
    .actions-toolbar {
        padding-top: $account-margin-bottom;
        padding-bottom: $account-margin-bottom;
        @include clearfix;

        // Buttons
        .action {
            @extend %btn;
            display: inline-block;
        }

        > .primary,
        > .secondary {
            display: block;
            width: 50%;
        }

        > .primary {
            float: right;

            button.action,
            a.action {
                float: right;
            }
        }

        > .secondary {
            float: left;

            button.action,
            a.action {
                @extend %btn-ghost;
                float: left;
            }
        }
    }

    // Forms
    .form {
        .fieldset {
            margin-bottom: 0;

            .label {
                text-transform: none;
            }
        }

        .actions-toolbar {
            .action {
                float: right;
            }
        }
    }

    // Pager Toolbar
    .pager {
        padding-top: $account-margin-bottom;
        @include clearfix;

        // Toolbar Amount
        .toolbar-amount,
        .limiter {
            display: block;
            float: left;
            margin: 0;
        }

        // Amount
        .toolbar-amount {
            padding-top: 8px;
            width: 40%;
        }

        // Limiter
        .limiter {
            text-align: right;
            width: 60%;

            &-label, &-options, &-text {
                @extend %font-base;
                display: inline-block;
                margin: 0 5px;
                width: auto;
            }

            &-label {
                margin-left: 0;
            }

            &-text {
                margin-right: 0;
            }
        }
    }

    // Table Wrapper
    .table-wrapper {
        @include responsive-tables();

        th, td {
            padding: 5px;

            @include bp(min-width, $bp-medium) {
                padding: 12px 10px;
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }
    }


    // Rating Summary
    .rating-summary {
        margin: 0;
    }

    //*** Hide Elements ***//

    .account-nav .title {
        display: none;
    }

    .action.view {
        padding-right: 5px;
    }
}


//
// Account Specific Pages
// ---------------------------------------------

//*** Dashboard ***//

.customer-account-index {
    .block-reviews-dashboard {

        .rating-summary,
        .product-name {
            @extend %font-base;
            vertical-align: middle;
            display: inline-block;
        }

        .product-name {
            margin-right: 10px;
        }
    }

    .page-main {
        .block-content {
            flex-wrap: wrap;
            display: flex;

            .box {
                color: $color-base;
                font-size: rem(14px);
                line-height: 1.64;
                width: 50%;
                @include bp(max-width, $bp-xsmall) {
                    width: 100%;
                }

                &-title {
                    color: $color-black;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

//*** Orders Page ***//
.sales-order-view {
    #my-orders-table {
        display: table-footer-group;

        .subtotal_incl,
        .totals-tax-summary {
            display: none;
        }
    }
    #order-totals {
        display: flex;
        flex-direction: column;

        tr {
            align-self: flex-end;
        }

        .mark {
            padding-right: 10px;
        }

        .subtotal_excl {
            order: 1;
        }

        .totals.tax.details {
            order: 3;
        }

        .discount {
            order: 2;
        }

        .shipping {
            order: 4;
        }

        .grand_total_incl {
            order: 5;
        }
    }
}

//*** Reward Page ***//

.magento-reward-customer-info {

    .block-reward-info .reward-balance {
        margin-bottom: 10px !important;
        font-size: rem(14px);
    }
}

.flex-wrap {
    flex-wrap: wrap;
    display: flex;
    .field {
        align-self: baseline;
    }
}

.order-details-items {
    .item-options,
    .item.options {
        dt,
        dd {
            display: block;
        }
    }
}

.invoice {
    .table-caption {
        display: none;
    }
}
