//
// Number Input w/ Incrementer
// --------------------------------------

.incrementer {
    $border: 0;
    $height: 50px - ($border*2); // reduce by border top / bottom
    position: relative;
    overflow: hidden;
    display: inline-block;

    // Common
    .increment, .decrement {
        @extend %font-base;
        background: $color-grey-light;
        color: $color-primary;
        line-height: $height - 1px;
        background: $color-offwhite;
        position: absolute;
        text-align: center;
        font-size: 14px;
        display: block;
        height: $height;
        cursor: pointer;
        width: 44px;
        border: 1px solid $color-grey;
        padding: 0;
        top: 0;

        @include bp(min-width, $bp-medium, true) {
            height: $height - 15px;
            line-height: $height - 16px;
            width: 33px;
        }
    }

    // Subtraction
    .increment {
        right: 0;
    }

    // Addition
    .decrement {
        left: 0;
    }

    input {
        @extend %font-base;
        color: $color-base-light;
        text-align: center;
        letter-spacing: 0;
        font-size: 14px;
        display: block;
        height: $height;
        outline: none;
        border: 1px solid $color-grey;
        max-width: 150px;
        background: $color-offwhite;
        padding: 0; //Required for IE

        @include bp(min-width, $bp-medium, true) {
            height: $height - 15px;
            line-height: $height - 16px;
            width: 110px;
        }

        &:focus {
            box-shadow: none;
            outline: none;
            border: none;
        }
    }
}