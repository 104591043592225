// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//    Common
//--------------------------------------
// Prices

.price-box {
    font-size: 30px;

    .price-label {
        display: none;
    }

    .special-price {
        color: $color-error;

        &.single {
            color: inherit;
        }
    }

    .old-price {

        .price {
            text-decoration: line-through;
            font-size: 1rem;
        }
    }

    .price {
        font-size: 20px;
        font-weight: bold;
    }

    .you-save {
        font-size: 1rem;
        color: $color-error;
    }
}

.product-items {
    .price-box .old-price .price,
    .price-box .you-save {
        font-size: 1rem; //Why is this not scalable?
    }
}